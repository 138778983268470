import React, { FC } from 'react'

import styles from './HeaderButtons.module.scss'
import cn from 'classnames'
import { LandingButton } from 'src/shared/ui/LandingButton'
import { TelegramLink } from '../TelegramLink/TelegramLink'
import { BLOGGER_REGISTRATION_ROUTE, CUSTOMER_REGISTRATION_ROUTE } from 'src/shared/config/consts'

export interface HeaderButtonsPropsType {
  className: string
  type: 'customer' | 'blogger'
}

export const HeaderButtons: FC<HeaderButtonsPropsType> = ({ className, type }) => {
  return (
    <div className={cn(styles.HeaderButtons, type === 'blogger' && styles.HeaderButtons__reverse, className)}>
      <div className={styles.Group}>
        <LandingButton
          className={styles.LandingButton}
          kind={type === 'customer' ? 'primary' : 'ghost'}
          to={CUSTOMER_REGISTRATION_ROUTE}
        >
          Регистрация селеров
        </LandingButton>
        <TelegramLink link="https://t.me/Marketiqchat_bot">ТГ канал для селеров</TelegramLink>
      </div>
      <div className={styles.Group}>
        <LandingButton
          className={styles.LandingButton}
          kind={type === 'blogger' ? 'primary' : 'ghost'}
          to={BLOGGER_REGISTRATION_ROUTE}
        >
          Регистрация блогеров
        </LandingButton>
        <TelegramLink link="https://t.me/marketiq_news">ТГ канал для блогеров</TelegramLink>
      </div>
    </div>
  )
}
