import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { CABINET_TASKS_ROUTE, CABINET_PRODUCTS_ROUTE, CABINET_PROFILE_ROUTE, CABINET_TOPICS_ROUTE, CABINET_MARKETPLACES_ROUTE, CABINET_USERS_ROUTE } from 'src/shared/config/consts'
import { Logo } from '../Logo/Logo'
import { TelegramLink } from '../TelegramLink/TelegramLink'
import { IconButton } from '../IconButton/IconButton'
import { useUserInfo } from 'src/shared/model/useUser'
import { RoleType } from 'src/shared/config/types'
import { Avatar } from 'src/shared/ui/Avatar'
import { useCustomerProfile } from 'src/shared/model/useCustomerProfile'
import { plural } from 'src/shared/lib/plural'

import styles from './Header.module.scss'

export const Header: FC = () => {
  const { user } = useUserInfo()
  const navigate = useNavigate()

  const handleProfileClick = useCallback(() => navigate(CABINET_PROFILE_ROUTE), [navigate])

  const profile =  useCustomerProfile()

  return (
    <div className={styles.Header}>
      <Logo />
      <div className={styles.Navigation}>
        {user?.role === RoleType.CUSTOMER &&
          <>
            <a className={styles.NavigationItem} href={CABINET_PRODUCTS_ROUTE}>Мои товары</a>
            <a className={styles.NavigationItem} href={CABINET_TASKS_ROUTE}>Мои задания</a>
            {profile && (
              <a className={styles.NavigationItem}>
                {`Мой баланс: ${profile.total} ${plural(['интеграция', 'интеграции', 'интеграций'], profile.total)}`}
              </a>
            )}
          </>
        }
        {user?.role === RoleType.BLOGER &&
          <>
            <a className={styles.NavigationItem} href={CABINET_TASKS_ROUTE}>Мои задания</a>
          </>
        }
        {user?.role === RoleType.SUPER_ADMIN &&
          <>
            <a className={styles.NavigationItem} href={CABINET_USERS_ROUTE}>Анкеты</a>
            <a className={styles.NavigationItem} href={CABINET_PRODUCTS_ROUTE}>Товары</a>
            <a className={styles.NavigationItem} href={CABINET_TASKS_ROUTE}>Задания</a>
            <a className={styles.NavigationItem} href={CABINET_TOPICS_ROUTE}>Топики товаров</a>
            <a className={styles.NavigationItem} href={CABINET_MARKETPLACES_ROUTE}>Маркетплейсы</a>
          </>
        }
      </div>
      <div className={styles.Menu}>
        <TelegramLink />
        <IconButton icon="settings" />
        <IconButton icon="bell" />
      </div>
      {user && <Avatar className={styles.Avatar} avatar={user.avatar} firstName={user.firstName} onClick={handleProfileClick}/>}
    </div>
  )
}
