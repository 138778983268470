import React, { FC } from 'react'
import { Field, FieldPropsType } from '../Field'
import { Checkbox, CheckboxPropsType } from '../Checkbox'

export type CheckboxFieldPropsType = Omit<FieldPropsType, 'children'> & CheckboxPropsType

export const CheckboxField: FC<CheckboxFieldPropsType> = ({ className, label, id, error, description, isRequired, withHiddenLabel, ...rest }) => {
  return (
    <Field className={className} kind="cabinet" label={label} id={id} error={error} description={description} isRequired={isRequired} withHiddenLabel={withHiddenLabel}>
      <Checkbox id={id} isInvalid={Boolean(error)} {...rest} />
    </Field>
  )
}
