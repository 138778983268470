import React, { FC, useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Modal } from 'src/shared/ui/Modal'
import { Flex } from 'src/shared/ui/Flex/Flex'
import { Textarea } from 'src/shared/ui/Textarea'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { AdminAPI, CommonMessageResponse, HttpResponse } from 'src/shared/api'
import { useBloggerProfile } from 'src/shared/model/useBloggerProfile'
import { Collection } from 'src/shared/ui/Collection'
import { BloggerStatusType, CollectionType } from 'src/shared/config/types'
import { useNavigate } from 'react-router'
import { CABINET_PROFILE_ROUTE } from 'src/shared/config/consts'
import { Field } from 'src/shared/ui/Field'

import styles from './OrderModal.module.scss'
import { ValueField } from 'src/shared/ui/ValueField'

const numberFormat = new Intl.NumberFormat('ru')

interface OrderModalPropsType {
  orderId: number
  onClose(): void
}

export const OrderModal: FC<OrderModalPropsType> = ({ orderId, onClose }) => {
  const navigate = useNavigate()
  const [message, setMessage] = useState<string>()
  const [error, setError] = useState<string>()

  const queryClient = useQueryClient()

  const profile = useBloggerProfile()

  const { data: ordersData } = useQuery({
    queryKey: ['orders', orderId],
    queryFn: () => AdminAPI.api.ordersControllerFindOne(orderId),
  })
  const order = useMemo(() => ordersData?.data, [ordersData])

  const createOrderResponseMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    number
  >({
    mutationFn: AdminAPI.api.orderResponsesControllerCreate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['orders'] })
      onClose()
    },
    onError: (error) => {
      if (error.error?.message) {
        setError(error.error.message)
      }
    }
  })
  const isLoading = createOrderResponseMutation.isPending

  const handleCreateOrderClick = useCallback(() => {
    setError(undefined)

    createOrderResponseMutation.mutate(orderId)
  }, [createOrderResponseMutation, orderId])

  const handleOnVerificationClick = useCallback(() => navigate(CABINET_PROFILE_ROUTE), [navigate])

  if (!order || !profile) {
    return null
  }

  return (
    <Modal
      className={styles.OrderModal}
      title={order.title!}
      onClose={onClose}
    >
      <Flex className={styles.Body} flexDirection="column">
        <Flex justifyContent="space-between">
          <span className={styles.Marketplace}>{order.marketplaceName}</span>
          <Collection className={styles.Collection} collection={order.collection!} />
        </Flex>
        <ValueField label="Артикул">{order.article}</ValueField>
        <ValueField label="Цена для фильтра:">{numberFormat.format(order.price!)} ₽</ValueField>
        <ValueField label="Ключевое слово для выкупа товара:">{order.keyWord}</ValueField>
        <ValueField label="Требования к отзыву на маркетплейсе:">{order.commentMarketplace}</ValueField>
        <ValueField label="Требования к рекламной интеграции:">{order.commentIntegration} </ValueField>
        {profile.status === BloggerStatusType.ACTIVATED && (
          <>
            <Textarea
              kind="cabinet"
              placeholder="При желании можете оставить сообщение селлеру"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            <Field id="createOrder" error={error} kind="cabinet" withHiddenLabel>
              <CabinetActionButton className={styles.CabinetActionButton} disabled={isLoading} onClick={handleCreateOrderClick} >
                {order.collection === CollectionType.AUTOMATIC ? 'Участвовать' : 'Подать заявку на участие'}
              </CabinetActionButton>
            </Field>
          </>
        )}
        {profile.status === BloggerStatusType.NOT_ACTIVATED && !profile.isFullInfo && (
          <CabinetActionButton className={styles.CabinetActionButton} onClick={handleOnVerificationClick}>
            Пройти верификацию
          </CabinetActionButton>
        )}
      </Flex>
    </Modal>
  )
}
