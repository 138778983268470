import React, { FC, useCallback, useState } from 'react'
import { OrderResponsesResponseDto, RoleType } from 'src/shared/config/types'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Field } from 'src/shared/ui/Field'
import { Uploader } from 'src/shared/ui/Uploader'
import { Flex } from 'src/shared/ui/Flex'
import { MediaSections } from '../MediaSections/MediaSections'

import styles from './WaitPaymentStage.module.scss'

interface WaitPaymentStagePropsType {
  role: RoleType
  orderResponse: OrderResponsesResponseDto
  isSendReportLoading?: boolean
  onSendReport?(image: File): void
}

export const WaitPaymentStage: FC<WaitPaymentStagePropsType> = ({ role, orderResponse, isSendReportLoading, onSendReport }) => {
  const [image, setImage] = useState<File>()

  const handleButtonClick = useCallback(() => onSendReport?.(image!), [onSendReport, image])

  const isButtonDisabled = isSendReportLoading || !image

  return (
    <>
      <Flex className={styles.WaitPaymentStage} flexDirection="column">
        <MediaSections orderResponse={orderResponse} />
        {role === RoleType.CUSTOMER && (
          <>
            <div className={styles.Title}>
              Блогер ждет оплату. Отправьте ее на указанные реквизиты и прикрепите скриншот оплаты
            </div>
            {orderResponse.bloger.bankDetails.map((item: any, index: number) => (
              <Flex key={index} className={styles.BankDetails} flexDirection="column" gap={24}>
                <Flex flexDirection="column" gap={8}>
                  <span className={styles.Label}>Номер карты:</span>
                  <span className={styles.Text}>{item.accountNumber ?? '-'}</span>
                </Flex>
                <Flex flexDirection="column" gap={8}>
                  <span className={styles.Label}>Номер телефона:</span>
                  <span className={styles.Text}>{item.accountPhone ?? '-'}</span>
                </Flex>
                <Flex flexDirection="column" gap={8}>
                  <span className={styles.Label}>Название банка:</span>
                  <span className={styles.Text}>{item.bankName ?? '-'}</span>
                </Flex>
                <Flex flexDirection="column" gap={8}>
                  <span className={styles.Label}>ФИО:</span>
                  <span className={styles.Text}>{item.names ?? '-'}</span>
                </Flex>
              </Flex>
            ))}
            <Field className={styles.UploaderField} id="photo" label="Прикрепите скриншот оплаты:" kind="cabinet">
              <Uploader
                placeholder="Загрузить скриншот"
                type="image"
                isDisabled={isSendReportLoading}
                file={image}
                onChange={setImage}
              />
            </Field>
          </>
        )}
      </Flex>
      {role === RoleType.CUSTOMER && (
        <CabinetButton
          className={styles.CabinetButton}
          disabled={isButtonDisabled}
          onClick={handleButtonClick}
        >
          Отправить отчет об оплате
        </CabinetButton>
      )}
    </>
  )
}
