import React from 'react'

export const INTERACTION_STAGES = [
  {
    title: '1) Выбор тарифа и оплата',
    text: (
      <span className="preLineText">{`Выбираете подходящий тариф и совершаете оплату у нас на сайте или по счету
  (необходимо связаться со службой поддержки)`}</span>
    ),
  },
  {
    title: '2) Связь с персональным менеджером и службой поддержки',
    text: (
      <span className="preLineText">{`В течение 24 часов с вами свяжется персональный менеджер и создаст с вами
  общий рабочий чат со службой поддержки для быстрой обратной связи`}</span>
    ),
  },
  {
    title: '3) Создаете задание в личном кабинете на платформе Marketiq',
    text: (
      <span className="preLineText">{`Отправляете ссылку на ваш товар, выбираете нужный вам маркетплейс, карту и
  товар которого необходимо раскрутить, делаете ТЗ для выкупа, отзыва и рекламной
  интеграции`}</span>
    ),
  },
  {
    title: '4) Выбираете функцию автоматического или ручного сбора блогеров:',
    text: (
      <span className="preLineText">{`Автоматический сбор блогеров:

  Этот подход идеален для тех, кто стремится быстро найти большое количество
  потенциальных блогеров. Благодаря поисковому алгоритму нашего сайта, задания
  будут автоматически показываться соответствующим блогерам в зависимости от их
  интересов и категорий блога. Отклики будут распределяться среди тех, кто первым
  откликнется на задание. Этот метод эффективен для генерации регулярного контента
  во внешней рекламе и внутренней рекламы.
  
  Ручной сбор блогеров:
  
  Ручной подход позволяет гораздо точнее подходить к отбору блогеров, учитывая
  специфические требования и критерии вашего задания. Вы сможете изучить целевую
  аудиторию блогера, его эстетику и другие публикации, чтобы удостовериться, что он
  идеально подходит для вашего задания. Ручной отбор также ориентирован на
  увеличение внешнего трафика и повышение видимости вашего контента.`}</span>
    ),
  },
  {
    title: '5) Наш сервис и персональный менеджер начинают уведомлять блогеров о вашем предложении через:',
    text: (
      <span className="preLineText">{`- публикацию на платформе,
  - мобильное приложение,
  - email-рассылку блогерам,
  - социальные сети и каналы сервиса,
  - персональные звонки для приглашения блогеров к участию.`}</span>
    ),
  },
  {
    title: '6) Получение заявок от блогеров',
    text: (
      <span className="preLineText">{`- Вам поступают заявки от заинтересованных блогеров при автоматическом сборе
  блогеров и заказ запускается в исполнение
  - Вам поступают заявки от заинтересованных блогеров и вы можете самостоятельно
  выбрать тех, кто вам подходит.
  - Предоставляется статистика по аккаунтам блогеров и предыдущим интеграциям.
  - При необходимости можно запросить дополнительные данные у блогеров.`}</span>
    ),
  },
  {
    title: '7) Согласование ТЗ с блогерами',
    text: (
      <span className="preLineText">{`Менеджер связывается с каждым блогером для:
  - проверки понимания технического задания и рекламного посыла,
  - согласования даты выхода рекламы,
  - напоминания о необходимости загрузки рекламного контента и статистики.`}</span>
    ),
  },
  {
    title: '8) Публикация рекламы блогерами',
    text: (
      <span className="preLineText">{`Блогеры создают и размещают рекламу, включая ссылки на ваше предложение,
  UTM-метки и упоминания акций (при наличии).
  В течение 24 часов после публикации блогеры загружают контент и статистику на
  платформу.`}</span>
    ),
  },
  {
    title: '9) Контроль качества рекламы',
    text: (
      <span className="preLineText">{`Отдел качества следит за своевременной загрузкой данных и соблюдением
  требований ТЗ.
  В случае нарушений блогерам предлагается исправить ошибки, иначе они
  блокируются на платформе.`}</span>
    ),
  },
  {
    title: '10) Автоматическая отчетность',
    text: (
      <span className="preLineText">{`Платформа автоматически генерирует отчеты о результативности кампании, которые
  доступны в вашем личном кабинете:
  - статистика охватов, переходов и заявок от каждого блогера,
  - сводный результат по всем блогерам с возможностью отслеживания динамики.`}</span>
    ),
  },
  {
    title: '11) Анализ результатов с менеджером',
    text: (
      <span className="preLineText">{`Менеджер помогает вам проанализировать итоги кампании. При необходимости
  корректируется техническое задание или предложение для следующей итерации.`}</span>
    ),
  },
]
