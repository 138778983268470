import React, { FC } from 'react'
import { OrderResponsesResponseDto } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'
import { MediaSections } from '../MediaSections/MediaSections'

import styles from './ProgressStage.module.scss'

interface ProgressStagePropsType {
  orderResponse: OrderResponsesResponseDto
}

export const ProgressStage: FC<ProgressStagePropsType> = ({ orderResponse }) => {
  return (
    <Flex className={styles.ProgressStage} flexDirection="column">
      <MediaSections orderResponse={orderResponse} />
      <div className={styles.Title}>
        3 раздел. Реклама
      </div>
    </Flex>
  )
}
