import { useQuery } from '@tanstack/react-query'
import React, { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { AdminAPI } from 'src/shared/api'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Collection } from 'src/shared/ui/Collection'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CustomReporterType, OrderStatusType } from 'src/shared/config/types'
import { PendingStage } from '../PendingStage'
import { ConfirmedStage } from '../ConfirmedStage/ConfirmedStage'
import { WaitPaymentStage } from '../WaitPaymentStage'
import { WaitPayConfirmStage } from '../WaitPayConfirmStage/WaitPayConfirmStage'
import { CompletedSecondStepStage } from '../CompletedSecondStepStage/CompletedSecondStepStage'
import { PaidStage } from '../PaidStage/PaidStage'
import { Section } from '../Section/Section'
import { ValueField } from 'src/shared/ui/ValueField'
import { Block } from '../Block/Block'

import styles from './BloggerCabinetTask.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

export const BloggerCabinetTask: FC = () => {
  const { taskId } = useParams()
  const orderId = Number(taskId)

  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_TASKS_ROUTE), [navigate])

  const { data: ordersData } = useQuery({
    queryKey: ['orders', 'my', orderId],
    queryFn: () => AdminAPI.api.ordersControllerFindOneBlogerReport(orderId),
  })
  const order = ordersData?.data

  const renderStage = useCallback(() => {
    if (!order) {
      return null
    }

    if (order.status !== OrderStatusType.IN_PROGRESS) {
      return null
    }

    // @ts-ignore
    const reportStatus = order.customReporters?.status
    // @ts-ignore
    const responseId = order.customReporters?.id
    // @ts-ignore
    const paymentScreenshot = order.customReporters?.paymentScreenshot

    if (reportStatus === CustomReporterType.PENDING) {
      return <PendingStage />
    }

    if (reportStatus === CustomReporterType.CONFIRMED) {
      return <ConfirmedStage orderId={orderId} responseId={responseId} />
    }

    if (reportStatus === CustomReporterType.WAIT_PAYMENT) {
      return <WaitPaymentStage orderId={orderId} responseId={responseId} />
    }

    if (reportStatus === CustomReporterType.WAIT_PAY_CONFIRM) {
      return <WaitPayConfirmStage orderId={orderId} responseId={responseId} paymentScreenshot={paymentScreenshot} />
    }

    if (reportStatus === CustomReporterType.PAID) {
      return <PaidStage orderId={orderId} responseId={responseId} />
    }

    if (reportStatus === CustomReporterType.COMPLETED_STEP_2) {
      return <CompletedSecondStepStage orderId={orderId} responseId={responseId} />
    }
  }, [order, orderId])

  if (!order) {
    return null
  }

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <Flex className={styles.Main} alignItems="center">
          <img className={styles.Photo} src={order.photoUrl} />
          <Flex flexDirection="column" gap={8}>
            <span className={styles.Title}>{order.title}</span>
            <Collection collection={order.collection!} />
          </Flex>
        </Flex>
      </Flex>
      <Flex className={styles.Body}>
        <Section title="Техническое задание">
          <Block className={styles.Block}>
            <ValueField label="Артикул:">{order.article}</ValueField>
            <ValueField label="Цена для фильтра:">{`${numberFormat.format(order.price!)} ₽`}</ValueField>
            <ValueField label="Ключевое слово для выкупа товара:">{order.keyWord}</ValueField>
            <ValueField label="Требования к отзыву на маркетплейсе:">{order.commentMarketplace}</ValueField>
            <ValueField label="Требования к рекламной интеграции:">{order.commentIntegration}</ValueField>
          </Block>
        </Section>
        {renderStage()}
      </Flex>
    </CabinetPageLayout>
  )
}
