import React, { FC } from 'react'
import { Flex } from 'src/shared/ui/Flex'

import styles from './Profile.module.scss'
import { UserResponseDto } from 'src/shared/api'
import { Avatar } from 'src/shared/ui/Avatar'
import { TextInputField } from 'src/shared/ui/TextInputField'
import { PhoneInputField } from 'src/shared/ui/PhoneInputField'
import { RoleType } from 'src/shared/config/types'

interface ProfilePropsType {
  user: UserResponseDto
}

export const Profile: FC<ProfilePropsType> = ({ user }) => {
  const { avatar, firstName, lastName, phone, role, customer } = user

  return (
    <Flex className={styles.InputsContainer} flexDirection="column" gap={32}>
      <Avatar className={styles.Avatar} avatar={avatar} firstName={firstName} />
      <TextInputField
        id="firstName"
        kind="cabinet"
        label="Имя"
        value={firstName}
        readOnly
      />
      <TextInputField
        id="lastName"
        kind="cabinet"
        label="Фамилия"
        value={lastName}
        readOnly
      />
      {role === RoleType.CUSTOMER && (
        <TextInputField
          id="inn"
          kind="cabinet"
          label="ИНН"
          value={customer?.inn}
          readOnly
        />
      )}
      <PhoneInputField
        id="phone"
        kind="cabinet"
        label="Телефон"
        value={phone}
        readOnly
      />
    </Flex>
  )
}
