import React, { FC, ReactNode } from 'react'
import cn from 'classnames'
import authLayoutBackground1 from 'src/shared/assets/auth_layout_background_1.png'
import authLayoutBackground2 from 'src/shared/assets/auth_layout_background_2.png'
import { Logo } from 'src/shared/ui/Logo'

import styles from './AuthLayout.module.scss'

interface AuthLayoutPropsType {
  className?: string,
  children: ReactNode,
}

export const AuthLayout: FC<AuthLayoutPropsType> = ({ className, children }) => {
  return (
    <div className={cn(styles.AuthLayout, className)}>
      <div className={styles.Container}>
        <div className={styles.Background}>
          <img className={styles.AuthLayoutBackground1} src={authLayoutBackground1} />
          <img className={styles.AuthLayoutBackground2} src={authLayoutBackground2} />
        </div>
        <div className={styles.Header}>
          <Logo/>
        </div>
        {children}
      </div>
    </div>
  )
}
