import React, { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Tabs, TabType } from 'src/shared/ui/Tabs'
import { BloggerType, CollectionType, CustomReporterType, OrderByCustomerResponseDto, RoleType } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'
import { Collection } from 'src/shared/ui/Collection'
import { OrderStatus } from 'src/shared/ui/OrderStatus'
import { RejectedBlogger } from '../RejectedBlogger/RejectedBlogger'
import { RespondedBlogger } from '../RespondedBlogger/RespondedBlogger'
import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { WaitingForPaymentBlogger } from '../WaitingForPaymentBlogger/WaitingForPaymentBlogger'
import { PaymentSentBlogger } from '../PaymentSentBlogger/PaymentSentBlogger'
import { SubmittedTheReportBlogger } from '../SubmittedTheReportBlogger/SubmittedTheReportBlogger'
import { ProgressBlogger } from '../ProgressBlogger/ProgressBlogger'
import { useUserInfo } from 'src/shared/model/useUser'
import { StagesEnum } from 'src/shared/ui/ProgressBar/ProgressBar'

import styles from './CabinetTaskPageLayout.module.scss'

interface CabinetTaskPageLayoutPropsType {
  order: OrderByCustomerResponseDto
  orderId: number
  onConfirm?(item: BloggerType): void
  onReject?(item: BloggerType): void
}

export const CabinetTaskPageLayout: FC<CabinetTaskPageLayoutPropsType> = ({ order, orderId, onConfirm, onReject }) => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])
  const openResponse = useCallback((reportId: number) => navigate(`${CABINET_TASKS_ROUTE}/${orderId}/responses/${reportId}`), [orderId, navigate])

  const [activeTabId, setActiveTabId] = useState<string>(order.collection === CollectionType.MANUAL ? CustomReporterType.PENDING : CustomReporterType.CONFIRMED)

  const { user } = useUserInfo()
  const role = user?.role as RoleType

  const currentBloggers = useMemo(() => (order.blogers ?? []).filter(item => {
    if (activeTabId === CustomReporterType.PAID) {
      return item.reportStatus === CustomReporterType.PAID || item.reportStatus === CustomReporterType.COMPLETED_STEP_2
    }

    return item.reportStatus === activeTabId
  }), [order, activeTabId])

  const tabs = useMemo<TabType[]>(() => [
    ...(order.collection === CollectionType.MANUAL ?
      [
        {
          tabId: CustomReporterType.REJECTED,
          title: 'Отклонены',
          badge: order.customReportersStatistic?.REJECTED,
        },
        {
          tabId: CustomReporterType.PENDING,
          title: 'Откликнулись',
          badge: order.customReportersStatistic?.PENDING,
        }
      ] :
      []
    ),
    {
      tabId: CustomReporterType.CONFIRMED,
      title: 'Оформляют заказ',
      badge: order.customReportersStatistic?.CONFIRMED,
    },
    {
      tabId: CustomReporterType.WAIT_PAYMENT,
      title: 'Ждут оплаты',
      badge: order.customReportersStatistic?.WAIT_PAYMENT,
    },
    {
      tabId: CustomReporterType.WAIT_PAY_CONFIRM,
      title: 'Оплата отправлена',
      badge: order.customReportersStatistic?.WAIT_PAY_CONFIRM,
    },
    {
      tabId: CustomReporterType.PAID,
      title: 'Выполняют задание',
      // @ts-ignore
      badge: order.customReportersStatistic?.PAID + order.customReportersStatistic?.COMPLETED_STEP_2,
    },
    {
      tabId: CustomReporterType.COMPLETED,
      title: 'Сдали отчет',
      badge: order.customReportersStatistic?.COMPLETED,
    }
  ], [order])

  const renderBlogger = useCallback((item: BloggerType) => {
    const { reportId, reportStatus } = item
    const bloggerProps = item as BloggerPropsType

    if (reportStatus === CustomReporterType.REJECTED) {
      return (
        <RejectedBlogger
          key={reportId}
          role={role}
          {...bloggerProps}
        />
      )
    }

    if (reportStatus === CustomReporterType.PENDING) {
      return (
        <RespondedBlogger
          key={reportId}
          role={role}
          {...bloggerProps}
          onConfirmClick={onConfirm ? () => onConfirm(item) : undefined}
          onRejectClick={onReject ? () => onReject(item) : undefined}
        />
      )
    }

    if (reportStatus === CustomReporterType.CONFIRMED) {
      return (
        <Blogger
          key={reportId}
          {...bloggerProps}
        />
      )
    }

    if (reportStatus === CustomReporterType.WAIT_PAYMENT) {
      return (
        <WaitingForPaymentBlogger
          key={reportId}
          role={role}
          {...bloggerProps}
          onIntegrationDetailClick={() => openResponse(reportId)}
        />
      )
    }

    if (reportStatus === CustomReporterType.WAIT_PAY_CONFIRM) {
      return (
        <PaymentSentBlogger
          key={reportId}
          role={role}
          {...bloggerProps}
          onIntegrationDetailClick={() => openResponse(reportId)}
        />
      )
    }

    if (reportStatus === CustomReporterType.PAID || reportStatus === CustomReporterType.COMPLETED_STEP_2) {
      return (
        <ProgressBlogger
          key={reportId}
          {...bloggerProps}
          stage={reportStatus === CustomReporterType.PAID ? StagesEnum.STAGE_1 : StagesEnum.STAGE_2}
          onIntegrationDetailClick={() => openResponse(reportId)}
        />
      )
    }

    return (
      <SubmittedTheReportBlogger
        key={reportId}
        role={role}
        {...bloggerProps}
        onIntegrationDetailClick={() => openResponse(reportId)}
      />
    )
  }, [role, onConfirm, onReject, openResponse])

  return (
    <CabinetPageLayout>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <Flex alignItems="center" justifyContent="space-between" isFluid>
          <Flex alignItems="center" gap={20}>
            <img className={styles.Photo} src={order.photoUrl} />
            <Flex flexDirection="column" gap={8}>
              <span className={styles.Title}>{order.title}</span>
              <Flex alignItems="center" gap={16}>
                <span className={styles.Marketplace}>{order.marketplaceName}</span>
                <Collection collection={order.collection!} />
              </Flex>
            </Flex>
          </Flex>
          <OrderStatus
            status={order.status!}
            collection={order.collection!}
            confirmedCount={order.customReportersStatistic!.CONFIRMED}
            maxParticipants={order.maxParticipants!}
          />
        </Flex>
      </Flex>
      <Tabs
        className={styles.Tabs}
        activeTabId={activeTabId}
        tabs={tabs}
        onTabClick={setActiveTabId}
      />
      <Flex className={styles.Bloggers} alignItems="flex-start" gap={16} flexWrap="wrap">
        {currentBloggers.map(renderBlogger)}
      </Flex>
    </CabinetPageLayout>
  )
}
