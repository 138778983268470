import React, { FC } from 'react'
import { Section } from './Section/Section'
import { Block } from './Block/Block'
import { ValueField } from 'src/shared/ui/ValueField'

interface WaitPaymentStagePropsType {
  orderId: number
  responseId: number
}

export const WaitPaymentStage: FC<WaitPaymentStagePropsType> = () => {
  return (
    <Section title="Ожидание оплаты от селлера">
      <Block>
        <ValueField label="Селлер еще не предоставил подтверждение оплаты" />
      </Block>
    </Section>
  )
}
