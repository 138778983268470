import React, { FC, useCallback, useMemo, useState } from 'react'
import { AdminAPI, BankDetailsDto, CommonMessageResponse, HttpResponse, UserActivateDto, UserResponseDto } from 'src/shared/api'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { useNavigate } from 'react-router'
import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { Avatar } from 'src/shared/ui/Avatar'
import { TextInputField } from 'src/shared/ui/TextInputField'
import { DeepPartial, FormProvider, useForm } from 'react-hook-form'
import { PhoneInputField } from 'src/shared/ui/PhoneInputField'
import { ru } from 'date-fns/locale/ru'
import { format } from 'date-fns'
import { Media } from 'src/shared/ui/Media'
import { TopicsMultiSelectFormField } from 'src/shared/ui/TopicsMultiSelectFormField'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { BankDetailsFormField } from 'src/shared/ui/BankDetailsFormField'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UserStatusType } from 'src/shared/config/types'
import { RejectModal } from 'src/widgets/RejectModal'

import styles from './VerificationRequest.module.scss'

const FORM_ID = 'VerificationRequest'

interface VerificationRequestFormFields {
  instagramLink: string
  country: string
  city: string
  followersCount: string
  coveragesCount: string
  topics: {topicId: string}[]
  bankDetails: Omit<BankDetailsDto, 'id'>[]
}

interface VerificationRequestPropsType {
  user: UserResponseDto
}

export const VerificationRequest: FC<VerificationRequestPropsType> = ({ user }) => {
  const { avatar, firstName, lastName, phone, bloger } = user
  const { verifyInfo } = bloger ?? {}
  const { videoStatistics, genderStatistics, countriesStatistics, lastStoryStatistics, publicationStatistics } = verifyInfo ?? {}

  const [formData, setFormData] = useState<VerificationRequestFormFields>()

  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_USERS_ROUTE), [navigate])

  const defaultValues = useMemo<DeepPartial<VerificationRequestFormFields>>(() => ({
    instagramLink: user.bloger?.instagramLink,
    country: user.bloger?.country,
    city: user.bloger?.city,
    followersCount: String(user.bloger?.followersCount),
    coveragesCount: String(user.bloger?.coveragesCount),
    topics: (user.bloger?.topics as any[]).map(item => ({ topicId: String(item.id) })),
    bankDetails: user.bloger?.bankDetails as unknown as Omit<BankDetailsDto, 'id'>[]
  }), [user])

  const methods = useForm<VerificationRequestFormFields>({ defaultValues })
  const { watch, handleSubmit, setError } = methods
  const values = watch()

  const queryClient = useQueryClient()

  const { mutate: updateUserMutation, isPending: isLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UserActivateDto
  >({
    mutationFn: ( data: UserActivateDto ) => AdminAPI.api.managementControllerUserActivated(user.id, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] })
  })

  const updateUser = useCallback(async (data: VerificationRequestFormFields, status: UserStatusType) => {
    try {
      const userActivateDto: UserActivateDto = {
        status,
        followersCount: Number(data.followersCount),
        coveragesCount: Number(data.coveragesCount),
      }

      updateUserMutation(userActivateDto, {
        onSuccess: () => {
          navigate(CABINET_USERS_ROUTE, { replace: true })
        },
        onError: (error) => {
          if (error.error) {
            setError('instagramLink', error.error)
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }, [updateUserMutation])

  const activateUser = useCallback((data: VerificationRequestFormFields) => updateUser(data, UserStatusType.ACTIVATED), [updateUser])

  const handleReject = useCallback((data: VerificationRequestFormFields) => setFormData(data), [])

  const handleRejectModalSendForImprovement = useCallback(() => {
    if (formData) {
      updateUser(formData, UserStatusType.INCOMPLETE_DATA)
      setFormData(undefined)
    }
  }, [formData, updateUser])

  const handleRejectModalBlock = useCallback(() => {
    if (formData) {
      updateUser(formData, UserStatusType.CANCELED)
      setFormData(undefined)
    }
  }, [formData, updateUser])

  const handleRejectModalClose = useCallback(() => setFormData(undefined), [])

  const isTopicsValid = useMemo(() => values.topics.every((item) => item.topicId !== undefined), [values])

  const isBankDetailsValid = useMemo(() => values.bankDetails.every((item) =>
    item.accountNumber &&
    item.accountPhone &&
    item.bankName &&
    item.names
  ), [values])

  const isButtonDisabled =
    !isTopicsValid ||
    !isBankDetailsValid ||
    !values.instagramLink ||
    !values.followersCount ||
    !values.country ||
    !values.city ||
    isLoading

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>Заявка от блогера</h1>
      </Flex>
      <FormProvider {...methods}>
        <form id={FORM_ID} onSubmit={handleSubmit(activateUser)}>
          <Flex flexDirection="column" gap={24}>
            <span className={styles.SubTitle}>{`Создана ${format(new Date(), "dd MMMM yyyy", { locale: ru })}`}</span>
            <Flex className={styles.InputsContainer} flexDirection="column" gap={32}>
              <Avatar className={styles.Avatar} avatar={avatar} firstName={firstName} />
              <TextInputField
                id="firstName"
                kind="cabinet"
                label="Имя"
                value={firstName}
                readOnly
              />
              <TextInputField
                id="lastName"
                kind="cabinet"
                label="Фамилия"
                value={lastName}
                readOnly
              />
              <PhoneInputField
                id="phone"
                kind="cabinet"
                label="Телефон"
                value={phone}
                readOnly
              />
              <TextInputFormField
                id="instagramLink"
                name="instagramLink"
                label="Ссылка на Instagram"
                placeholder="Ссылка"
                kind="cabinet"
                disabled={isLoading}
              />
              <NumericInputFormField
                id="followersCount"
                name="followersCount"
                label="Количество подписчиков"
                placeholder="Укажите количество ваших подписчиков"
                kind="cabinet"
                disabled={isLoading}
              />
              <NumericInputFormField
                id="coveragesCount"
                name="coveragesCount"
                label="Количество охватов"
                placeholder="Укажите количество ваших охватов"
                kind="cabinet"
                disabled={isLoading}
              />
            </Flex>
            <Flex className={styles.InputsContainer} flexDirection="column" gap={32}>
              <Flex flexDirection="column" gap={24}>
                <h1 className={styles.MediaTitle}>Скриншоты и видео</h1>
                <Flex flexWrap="wrap" gap={16}>
                  <Media
                    type="video"
                    src={videoStatistics}
                    desciption="Видеозапись экрана общей статистики просмотров за 7,14,30 дней"
                  />
                  <Media
                    type="image"
                    src={genderStatistics}
                    desciption="Скриншот статистики возраста аудитории и пола"
                  />
                  <Media
                    type="image"
                    src={countriesStatistics}
                    desciption="Скриншот статистики городов аудитории"
                  />
                  <Media
                    type="image"
                    src={lastStoryStatistics}
                    desciption="Скриншот статистики последней истории, где прошло 24 ч и более"
                  />
                  <Media
                    type="image"
                    src={publicationStatistics}
                    desciption="Cкриншот статистики последнего поста, где прошло 24ч и более"
                  />
                </Flex>
              </Flex>
              <TextInputFormField
                id="country"
                name="country"
                label="Страна"
                placeholder="Страна"
                kind="cabinet"
                disabled={isLoading}
              />
              <TextInputFormField
                id="city"
                name="city"
                label="Город"
                placeholder="Город"
                kind="cabinet"
                disabled={isLoading}
              />
              <TopicsMultiSelectFormField
                id="topics"
                name="topics"
                fieldName="topicId"
                label="Тематика блога"
                placeholder="Выберите тематику из списка"
                addButtonText="Добавить тематику"
                disabled={isLoading}
              />
              <BankDetailsFormField
                id="bankDetails"
                name="bankDetails"
                label="Банковские реквизиты"
                disabled={isLoading}
              />
            </Flex>
          </Flex>
        </form>
      </FormProvider>
      <Flex className={styles.Buttons} flexDirection="column" gap={8}>
        <CabinetActionButton
          form={FORM_ID}
          kind="primary"
          disabled={isButtonDisabled}
        >
          Подтвердить
        </CabinetActionButton>
        <CabinetActionButton
          kind="ghost"
          disabled={isButtonDisabled}
          type="button"
          onClick={handleSubmit(handleReject)}
        >
          Отклонить
        </CabinetActionButton>
      </Flex>
      {formData && (
        <RejectModal
          blogger={user}
          onSendForImprovement={handleRejectModalSendForImprovement}
          onBlock={handleRejectModalBlock}
          onClose={handleRejectModalClose}
        />
      )}
    </CabinetPageLayout>
  )
}
