import React, { FC, useCallback, useState } from 'react'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Field } from 'src/shared/ui/Field'
import { Uploader } from 'src/shared/ui/Uploader'
import { useUpload } from 'src/shared/model/useUpload'
import { UpdateOrderResponseDto } from 'src/shared/api'
import { TextInputField } from 'src/shared/ui/TextInputField'
import { useUpdateOrderResponseMutation } from '../../api/useUpdateOrderResponseMutation'
import { Section } from '../Section/Section'
import { ValueField } from 'src/shared/ui/ValueField'
import { Block } from '../Block/Block'

import styles from './PaidStage.module.scss'

interface PaidStagePropsType {
  orderId: number
  responseId: number
}

export const PaidStage: FC<PaidStagePropsType> = ({ orderId, responseId }) => {
  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const [myPurchasesImage, setMyPurchasesImage] = useState<File>()
  const [shtrihcodeImage, setShtrihcodeImage] = useState<File>()
  const [adDate, setAdDate] = useState<string>()

  const {
    mutate: updateOrderResponse,
    isPending: isUpdateOrderResponseLoading
  } = useUpdateOrderResponseMutation(orderId, responseId)

  const isLoading = isUpdateOrderResponseLoading || isUploading

  const isButtonDisabled = isLoading || !myPurchasesImage || !shtrihcodeImage || !adDate

  const handleButtonClick = useCallback(async () => {
    try {
      setIsUploading(true)
      const screenshotMyPurchases = await upload(myPurchasesImage!)
      const screenshotShtrihcode = await upload(shtrihcodeImage!)
      setIsUploading(false)

      await updateOrderResponse({
        step: 2,
        data: {
          screenshotMyPurchases,
          screenshotShtrihcode,
          adDate,
        } as UpdateOrderResponseDto
      })
    } catch (e) {
      console.error(e)
      setIsUploading(false)
    }
  }, [myPurchasesImage, shtrihcodeImage, adDate, upload])

  return (
    <Section title="2 раздел. Выкуп">
      <Flex flexDirection="column">
        <Block className={styles.Block}>
          <ValueField label="Инструкция:">
            ТЕКСТ ИНСТРУКЦИИ*
          </ValueField>
          <Field id="image" label="Прикрепите скрин раздела покупок:" kind="cabinet">
            <Uploader
              type="image"
              file={myPurchasesImage}
              onChange={setMyPurchasesImage}
              isDisabled={isLoading}
              placeholder="Загрузить скриншот"
            />
          </Field>
          <Field id="image" label="Прикрепите фото разрезанного штрихкода:" kind="cabinet">
            <Uploader
              type="image"
              file={shtrihcodeImage}
              onChange={setShtrihcodeImage}
              isDisabled={isLoading}
            />
          </Field>
          <TextInputField
            id="date"
            label="Укажите дату выхода рекламы:"
            kind="cabinet"
            type="date"
            value={adDate}
            onChange={(event) => setAdDate(event.target.value)}
          />
        </Block>
        <CabinetButton
          className={styles.CabinetButton}
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
        >
          Отправить
        </CabinetButton>
      </Flex>
    </Section>
  )
}
