import { Api } from './AdminAPI'
export { StorageAPI } from './StorageAPI'
export * from './AdminAPI'

const customFetch: typeof fetch = async (url, options = {}) => {
  console.log('Fetching URL:', url)
  console.log('Request Options:', options)

  try {
    const response = await fetch(url, options)

    // Логирование получения заголовков
    const newAccessToken = response.headers.get('authorization')
    console.log('Received Authorization Header:', newAccessToken)

    // Логирование других заголовков
    const dateHeader = response.headers.get('date')
    console.log('Date Header:', dateHeader)

    // Проверка на наличие токена
    if (newAccessToken) {
      console.log('Storing new access token to localStorage')
      localStorage.setItem('accessToken', newAccessToken)
    } else {
      console.warn('No Authorization header received.')
    }

    // Логирование статуса ответа
    console.log('Response Status:', response.status)
    console.log('Response Status Text:', response.statusText)

    return response
  } catch (error) {
    // Логирование ошибок в случае сбоя запроса
    console.error('Error during fetch:', error)
    throw error // Перебрасываем ошибку дальше, если нужно обработать её в другом месте
  }
}

export const AdminAPI = new Api({
  baseUrl: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_SERVER_PROXY_URL : '',
  securityWorker: () => {
    const accessToken = localStorage.getItem('accessToken') as string
    const clientHash = localStorage.getItem('fpHash') as string

    console.log('Using Authorization Header:', accessToken)
    console.log('Using Client Hash:', clientHash)

    return {
      headers: {
        Authorization: accessToken,
        Client: clientHash,
      },
    }
  },
  customFetch,
})
