import React, { FC } from 'react'
import cn from 'classnames'

import styles from './Numbers.module.scss'

interface NumbersPropsType {
  className: string
}

export const Numbers: FC<NumbersPropsType> = ({ className }) => {
  return (
    <section className={cn(styles.Numbers, className)} aria-labelledby="numbers-title">
      <h2 className={styles.Title} id="numbers-title">
        Marketiq в цифрах
      </h2>
      <div className={styles.Items}>
        <div className={styles.ItemsGroup}>
          <div className={styles.Item}>
            <strong className={styles.ItemNumber}>5 024</strong>
            <div className={styles.ItemDescription}>Блогера</div>
          </div>
          <div className={styles.Item}>
            <strong className={styles.ItemNumber}>1 045</strong>
            <div className={styles.ItemDescription}>Брендов</div>
          </div>
          <div className={styles.Item}>
            <strong className={styles.ItemNumber}>14 000</strong>
            <div className={styles.ItemDescription}>Рекламных интеграций</div>
          </div>
        </div>
        <div className={styles.ItemsGroup}>
          <div className={styles.Item}>
            <strong className={styles.ItemNumber}>15 675</strong>
            <div className={styles.ItemDescription}>Товаров</div>
          </div>
          <div className={styles.Item}>
            <strong className={styles.ItemNumber}>300 450 421</strong>
            <div className={styles.ItemDescription}>Рекламных охватов</div>
          </div>
        </div>
      </div>
    </section>
  )
}
