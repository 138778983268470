import React, { FC, useMemo } from 'react'
import { useTopics } from 'src/shared/model/useTopics'
import { MultiSelectFormField, MultiSelectFormFieldPropsType } from 'src/shared/ui/MultiSelectFormField'

type TopicsMultiSelectFormFieldPropsType = Omit<MultiSelectFormFieldPropsType, 'options'>

export const TopicsMultiSelectFormField: FC<TopicsMultiSelectFormFieldPropsType> = (props) => {
  const topics = useTopics()

  const options = useMemo(() => topics.map(item => ({
    value: String(item.id),
    label: item.name
  })), [topics])

  return (
    <MultiSelectFormField
      options={options}
      {...props}
    />
  )
}
