import React, { FC, useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { SelectFormField } from 'src/shared/ui/SelectFormField'
import { Field } from 'src/shared/ui/Field'
import { SelectOptionType } from '../Select'
import { Icon } from '../Icon'

import styles from './MultiSelectFormField.module.scss'

export interface MultiSelectFormFieldPropsType {
  id: string
  name: string
  fieldName: string
  label: string
  placeholder: string
  options: SelectOptionType[]
  addButtonText: string
  disabled?: boolean
}

export const MultiSelectFormField: FC<MultiSelectFormFieldPropsType> = ({ id, name, fieldName, label, placeholder, options, addButtonText, disabled = false }) => {
  const { control } = useFormContext()

  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name
  });

  const handleAddButtonClick = useCallback(() => {
    if (fields.length < 3) {
      append({ [fieldName]: undefined })
    }
  }, [fields, append])

  return (
    <Field id={id} label={label} kind="cabinet">
      <div className={styles.MultiSelectFormField}>
        {fields.map((item, index) => (
          <div className={styles.Row} key={item.id}>
            <SelectFormField
              className={styles.SelectFormField}
              id={`${name}.${index}.${fieldName}`}
              name={`${name}.${index}.${fieldName}`}
              options={options}
              isInvalid={false}
              placeholder={placeholder}
              withHiddenLabel
              disabled={disabled}
            />
            <Icon
              className={styles.CloseIcon}
              name="close"
              onClick={() => {
                if (fields.length !== 1 && !disabled) {
                  remove(index)
                }
              }}
            />
          </div>
        ))}
        <button className={styles.AddButton} type="button" onClick={handleAddButtonClick} disabled={disabled}>
          {addButtonText}
        </button>
      </div>
    </Field>
  )
}
