import React, { FC, useCallback, useState } from 'react'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Field } from 'src/shared/ui/Field'
import { Uploader } from 'src/shared/ui/Uploader'
import { useUpload } from 'src/shared/model/useUpload'
import { UpdateOrderResponseDto } from 'src/shared/api'
import { useUpdateOrderResponseMutation } from '../../api/useUpdateOrderResponseMutation'
import { Section } from '../Section/Section'
import { Block } from '../Block/Block'
import { ValueField } from 'src/shared/ui/ValueField'

import styles from './ConfirmedStage.module.scss'

interface ConfirmedStagePropsType {
  orderId: number
  responseId: number
}

export const ConfirmedStage: FC<ConfirmedStagePropsType> = ({ orderId, responseId }) => {
  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const [video, setVideo] = useState<File>()
  const [image, setImage] = useState<File>()

  const {
    mutate: updateOrderResponse,
    isPending: isUpdateOrderResponseLoading
  } = useUpdateOrderResponseMutation(orderId, responseId)

  const isLoading = isUpdateOrderResponseLoading || isUploading

  const isButtonDisabled = isLoading || !video || !image

  const handleButtonClick = useCallback(async () => {
    try {
      setIsUploading(true)
      const orderVideo = await upload(video!)
      const orderScreenshot = await upload(image!)
      setIsUploading(false)

      await updateOrderResponse({
        step: 1,
        data: {
          orderVideo,
          orderScreenshot
        } as UpdateOrderResponseDto
      })
    } catch (e) {
      console.error(e)
      setIsUploading(false)
    }
  }, [video, image, upload])

  return (
    <Section title="1 раздел. Заказ">
      <Flex flexDirection="column">
        <Block className={styles.Block}>
          <ValueField label="Инструкция:">
            Запишите экран во время процесса заказа. После этого сделайте скриншот заказа. Прикрепите видео и скриншот, чтобы отправить эту информацию селлеру, получить оплату и продолжить дальнейшее выполнение задания.:
          </ValueField>
          <Field id="video" label="Прикрепите видео заказа:" kind="cabinet">
            <Uploader type="video" file={video} onChange={setVideo} isDisabled={isLoading} />
          </Field>
          <Field id="image" label="Прикрепите скриншот заказа:" kind="cabinet">
            <Uploader type="image" file={image} onChange={setImage} isDisabled={isLoading} placeholder="Загрузить скриншот" />
          </Field>
        </Block>
        <CabinetButton
          className={styles.CabinetButton}
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
        >
          Отправить
        </CabinetButton>
      </Flex>
    </Section>
  )
}
