import React, { FC, useCallback } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { phoneRules } from 'src/shared/lib/phoneRules'
import { Field } from '../Field'
import { Flex } from '../Flex'
import { NumericInputFormField } from '../NumericInputFormField'
import { PhoneInputFormField } from '../PhoneInputFormField'
import { TextInputFormField } from '../TextInputFormField'

import styles from './BankDetailsFormField.module.scss'

export const DEFAULT_BANK_DETAILS = {
  accountNumber: '',
  accountPhone: '',
  bankName: '',
  names: ''
}

interface BankDetailsFormFieldPropsType {
  id: string
  name: string
  label: string
  disabled?: boolean
}

export const BankDetailsFormField: FC<BankDetailsFormFieldPropsType> = ({ id, name, label, disabled }) => {
  const { control } = useFormContext()

  const {
    fields: bankDetailsFields,
    append: appendBankDetails,
    remove: removeBankDetails,
  } = useFieldArray({
    control,
    name
  })

  const handleAddBankDetailsButtonClick = useCallback(() => {
    appendBankDetails(DEFAULT_BANK_DETAILS)
  }, [appendBankDetails])

  return (
    <Field id={id} label={label} kind="cabinet">
      <Flex flexDirection="column" gap={16}>
        <span className={styles.Description}>
          Укажите информацию для получения оплаты от селлеров
        </span>
        {bankDetailsFields.map((item, index) => (
          <Flex key={item.id} flexDirection="column" gap={16}>
            <NumericInputFormField
              id={`${name}.${index}.accountNumber`}
              name={`${name}.${index}.accountNumber`}
              label="Номер карты"
              placeholder="Номер карты"
              kind="smallCabinet"
              disabled={disabled}
            />
            <PhoneInputFormField
              id={`${name}.${index}.accountPhone`}
              name={`${name}.${index}.accountPhone`}
              label="Номер телефона"
              placeholder="Номер телефона"
              rules={phoneRules}
              kind="smallCabinet"
              disabled={disabled}
            />
            <TextInputFormField
              id={`${name}.${index}.bankName`}
              name={`${name}.${index}.bankName`}
              label="Название банка"
              placeholder="Название банка"
              kind="smallCabinet"
              disabled={disabled}
            />
            <TextInputFormField
              id={`${name}.${index}.names`}
              name={`${name}.${index}.names`}
              label="ФИО"
              placeholder="ФИО"
              kind="smallCabinet"
              disabled={disabled}
            />
            {bankDetailsFields.length > 1 && (
              <button className={styles.Button} type="button" onClick={() => removeBankDetails(index)} disabled={disabled}>
                Удалить реквизиты
              </button>
            )}
          </Flex>
        ))}
        <button className={styles.Button} type="button" onClick={handleAddBankDetailsButtonClick} disabled={disabled}>
          Добавить реквизиты
        </button>
      </Flex>
    </Field>
  )
}
