import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CustomerOrderListResponseDto } from 'src/shared/config/types'
import { AdminAPI } from 'src/shared/api'
import { plural } from 'src/shared/lib/plural'
import { Task } from 'src/shared/ui/Task'
import { Flex } from 'src/shared/ui/Flex'

import styles from './AdminCabinetTasks.module.scss'

export const AdminCabinetTasks: FC = () => {
  const navigate = useNavigate()
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])

  const { data: ordersData, isLoading } = useQuery({
    queryKey: ['orders'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.managementControllerGetAllOrders({ page: 1, take: 200 }),
  })

  const orders = useMemo(() => (ordersData?.data?.data ?? []) as CustomerOrderListResponseDto[], [ordersData])

  if (isLoading) {
    return null
  }

  return (
    <CabinetPageLayout>
      <h1 className={styles.Title}>
        {`${orders.length} ${plural(['задание', 'задания', 'заданий'], orders.length)}`}
      </h1>
      <Flex className={styles.Tasks} flexDirection="column" gap={20}>
        {orders.map((item) => (
          <Task
            key={item.id}
            task={item}
            onClick={openTask}
          />
        ))}
      </Flex>
    </CabinetPageLayout>
  )
}
