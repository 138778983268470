import React, { ButtonHTMLAttributes, FC } from 'react'
import cn from 'classnames'
import { Icon, IconPropsType } from '../Icon'

import styles from './CabinetGhostButton.module.scss'

interface CabinetGhostButtonPropsType extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconPropsType['name']
}

export const CabinetGhostButton: FC<CabinetGhostButtonPropsType> = ({ className, children, icon, ...props }) => {
  return (
    <button
      className={cn(styles.CabinetGhostButton, className)}
      {...props}
    >
      {icon && <Icon className={styles.Icon} name={icon} />}
      {children}
    </button>
  )
}
