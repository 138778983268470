import React, { FC, useState } from 'react'
import cn from 'classnames'

import { ReactComponent as ArrowDown } from 'src/shared/assets/faq_arrow_down.svg'

import styles from './Accordion.module.scss'

type AccordionItem = {
  title: string
  text: string | React.JSX.Element
}

interface AccordionProps {
  data: AccordionItem[]
  kind?: 'landing' | 'cabinet'
  type?: 'single' | 'multiply'
  className?: string
}

export const Accordion: FC<AccordionProps> = ({ data, kind = 'landing', type = 'single', className }) => {
  const [openAccordionItems, setOpenAccordionItems] = useState<number[]>([])

  const toggleItem = (index: number) => {
    if (type === 'single') {
      setOpenAccordionItems((prev) => (prev.includes(index) ? [] : [index]))
    } else {
      setOpenAccordionItems((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]))
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent, index: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      // Обработка пробела
      toggleItem(index)
    }
  }

  return (
    <div
      className={cn(
        styles.Accordion,
        kind === 'landing' && styles.Accordion__landing,
        kind === 'cabinet' && styles.Accordion__cabinet,
        className,
      )}
    >
      {data.map(({ title, text }, index) => {
        const isAccordionItemOpen = openAccordionItems.includes(index)
        const itemId = `accordion-item-${index}`
        const buttonId = `accordion-button-${index}`

        return (
          <div
            key={index}
            className={cn(
              styles.AccordionItem,
              kind === 'landing' && styles.AccordionItem__landing,
              kind === 'cabinet' && styles.AccordionItem__cabinet,
            )}
            onClick={() => toggleItem(index)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            tabIndex={0} // Добавлен tabindex для доступности
          >
            <summary
              id={buttonId}
              className={styles.AccordionItemHeader}
              aria-expanded={isAccordionItemOpen}
              aria-controls={itemId}
            >
              <strong className={styles.AccordionItemTitle}>{title}</strong>
              <ArrowDown
                className={cn(styles.AccordionItemIcon, isAccordionItemOpen && styles.AccordionItemIcon__isOpen)}
              />
            </summary>
            <div
              id={itemId}
              role="region"
              aria-labelledby={buttonId}
              className={cn(
                styles.AccordionItemAnswerContainer,
                isAccordionItemOpen && styles.AccordionItemAnswerContainer__isOpen,
              )}
            >
              <div className={styles.AccordionItemAnswerContainer__overflow}>
                <div className={styles.AccordionItemAnswer}>{text}</div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
