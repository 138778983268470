import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetGhostButton } from 'src/shared/ui/CabinetGhostButton'
import { CustomReporterType, OrderResponsesResponseDto, RoleType } from 'src/shared/config/types'
import { ru } from 'date-fns/locale/ru'
import { format } from 'date-fns'
import { WaitPaymentStage } from '../WaitPaymentStage/WaitPaymentStage'
import { WaitPayConfirmStage } from '../WaitPayConfirmStage/WaitPayConfirmStage'
import { ProgressStage } from '../ProgressStage/ProgressStage'
import { CompletedStage } from '../CompletedStage/CompletedStage'
import { Avatar } from 'src/shared/ui/Avatar'
import { useUserInfo } from 'src/shared/model/useUser'

import styles from './CabinetIntegrationPageLayout.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

interface CabinetIntegrationPageLayoutPropsType {
  orderResponse: OrderResponsesResponseDto
  isSendReportLoading?: boolean
  onSendReport?(image: File): void
}

export const CabinetIntegrationPageLayout: FC<CabinetIntegrationPageLayoutPropsType> = ({ orderResponse, isSendReportLoading, onSendReport }) => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const { user } = useUserInfo()
  const role = user?.role as RoleType

  const renderStage = useCallback(() => {
    if (!orderResponse) {
      return null
    }

    if (orderResponse.status === CustomReporterType.WAIT_PAYMENT) {
      return <WaitPaymentStage role={role} orderResponse={orderResponse} isSendReportLoading={isSendReportLoading} onSendReport={onSendReport} />
    }

    if (orderResponse.status === CustomReporterType.WAIT_PAY_CONFIRM) {
      return <WaitPayConfirmStage orderResponse={orderResponse} />
    }

    if (orderResponse.status === CustomReporterType.PAID || orderResponse.status === CustomReporterType.COMPLETED_STEP_2) {
      return <ProgressStage orderResponse={orderResponse} />
    }

    if (orderResponse.status === CustomReporterType.COMPLETED) {
      return <CompletedStage orderResponse={orderResponse} />
    }
  }, [role, orderResponse, isSendReportLoading, onSendReport])

  return (
    <CabinetPageLayout className={styles.CabinetIntegrationPageLayout}>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        {orderResponse && (
          <Flex flexDirection="column" isFluid>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex flexDirection="column" gap={8}>
                <span className={styles.Title}>{`Интеграция №${orderResponse.id}`}</span>
                <span className={styles.CreatedAt}>{`Создана ${format(orderResponse.createAt, "dd MMMM yyyy", { locale: ru })}`}</span>
              </Flex>
              <CabinetGhostButton>
                Обратиться в поддержку
              </CabinetGhostButton>
            </Flex>
            <div className={styles.Blogger}>
              <Flex gap={12} alignItems="center">
                <Avatar
                  className={styles.Avatar}
                  avatar={orderResponse.bloger.user.avatar}
                  firstName={orderResponse.bloger.user.firstName}
                />
                <Flex flexDirection="column" gap={4}>
                  <span className={styles.FirstName}>{orderResponse.bloger.user.firstName}</span>
                  <span className={styles.InstagramLink}>{orderResponse.bloger.instagramLink}</span>
                </Flex>
              </Flex>
              <div className={styles.Info}>
                <div className={styles.Item}>
                  <span className={styles.Title}>Подписчики:</span>
                  <span className={styles.Value}>{numberFormat.format(orderResponse.bloger.followersCount)}</span>
                </div>
                <div className={styles.Item}>
                  <span className={styles.Title}>Средний охват:</span>
                  <span className={styles.Value}>{numberFormat.format(orderResponse.bloger.coveragesCount)}</span>
                </div>
                <div className={styles.Item}>
                  <span className={styles.Title}>Тематика:</span>
                  <div className={styles.Tags}>
                    {orderResponse.bloger.blogTopics.map((item: any) => <div key={item.topic.name} className={styles.Tag}>{item.topic.name}</div>)}
                  </div>
                </div>
              </div>
            </div>
            {renderStage()}
          </Flex>
        )}
      </Flex>
    </CabinetPageLayout>
  )
}
