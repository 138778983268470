import React, { FC, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { useBloggerProfile } from 'src/shared/model/useBloggerProfile'
import { BloggerStatusType } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'
import { Tabs } from 'src/shared/ui/Tabs'
import { BloggerVerification } from '../BloggerVerification/BloggerVerification'

import styles from './CabinetProfile.module.scss'

const VERIFICATION_TAB_ID = 'verification'

export const CabinetProfile: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])
  const profile = useBloggerProfile()

  const [activeTabId, setActiveTabId] = useState(VERIFICATION_TAB_ID)

  const tabs = useMemo(() => ([
    {
      tabId: VERIFICATION_TAB_ID,
      title: 'Верификация аккаунта',
    }
  ]), [])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <Flex flexDirection="column" gap={16} alignItems="flex-start">
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>Личный кабинет</h1>
      </Flex>
      {profile?.status === BloggerStatusType.NOT_ACTIVATED && !profile?.isFullInfo && (
        <>
          <Tabs
            className={styles.Tabs}
            tabs={tabs}
            activeTabId={activeTabId}
            onTabClick={setActiveTabId}
          />
          {activeTabId === VERIFICATION_TAB_ID && <BloggerVerification />}
        </>
      )}
    </CabinetPageLayout>
  )
}
