import { FC, ReactNode } from 'react'
import { Flex } from 'src/shared/ui/Flex'

import styles from './ValueField.module.scss'

interface ValueFieldPropsType {
  label?: string
  children?: ReactNode
}

export const ValueField: FC<ValueFieldPropsType> = ({ label, children }) => {
  return (
    <Flex className={styles.ValueField} flexDirection="column">
      {label && <span className={styles.Label}>{label}</span>}
      {children && <span className={styles.Value}>{children}</span>}
    </Flex>
  )
}
