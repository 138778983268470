/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CustomerInfoDto {
  /** ИНН заказчика */
  inn: string
}

export interface SignUpDto {
  /**
   * Номер телефона пользователя
   * @example "+79931234567"
   */
  phone: string
  /**
   * Пароль
   * @default "QWErty123"
   */
  password: string
  /** Имя пользователя */
  avatar?: string
  /** Имя пользователя */
  firstName: string
  /** Фамилия пользователя */
  lastName?: string
  /**
   * User role - "CUSTOMER" | "BLOGER"
   * @default "CUSTOMER"
   */
  role: string
  /** Information about the customer, the filling is checked if the role "CUSTOMER" */
  customer: CustomerInfoDto
}

export interface CommonMessageResponse {
  /** Message Text */
  message: string
  /** Error code */
  code?: number
}

export interface SignInDto {
  /**
   * Номер телефона пользователя
   * @example "+79931234567"
   */
  phone: string
  /**
   * Пароль
   * @default "QWErty123"
   */
  password: string
  /** Уникальный ключ клиента */
  client: string
}

export interface SignInUserResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** User phone */
  phone: string
  /** User avatar */
  avatar: string
  /** User first name */
  firstName: string
  /** User last name */
  lastName: string
  /** Moderator verification indicator */
  status: string
  /** User role */
  role: string
}

export interface SignInResponseDto {
  /** First token */
  accessToken: string
  /** User info */
  user: SignInUserResponseDto
}

export interface BankDetailsDto {
  /** Id */
  id: number
  /** Наименование банка */
  bankName: string
  /** Номер счета */
  accountNumber: string
  /**
   * Номер телефона счета пользователя
   * @example "+79931234567"
   */
  accountPhone: string
  /** Номер счета */
  names: string
}

export interface Topic {
  /** Id темы */
  topicId: number
}

export interface Topics {
  /** Перечисление тем, интересных блогеру для ДОБАВЛЕНИЯ */
  create: Topic[]
  /** Перечисление тем, интересных блогеру для УДАЛЕНИЯ */
  delete: Topic[]
}

export interface VerifyInfo {
  /** Описание к скриншоту */
  videoStatistics?: string
  /** Описание к скриншоту */
  genderStatistics?: string
  /** Описание к скриншоту */
  countriesStatistics?: string
  /** Описание к скриншоту */
  lastStoryStatistics?: string
  /** Описание к скриншоту */
  publicationStatistics?: string
}

export interface UpsertBloggerDto {
  /** Ссылка на инстаграм блогера */
  instagramLink?: string
  /** Город проживания */
  city?: string
  /** Страна проживания */
  country?: string
  /** Количество подписчиков */
  followersCount?: number
  /** Средний охват */
  coveragesCount?: number
  /** Информация по банковским реквизитам */
  bankDetails?: BankDetailsDto[]
  /** Интересуюшие тематики */
  blogTopics?: Topics
  /** Интересуюшие тематики */
  verifyInfo?: VerifyInfo
}

export interface UpdateCustomerDto {
  avatar?: string
  firstName?: string
  lastName?: string
  tgUsername?: string
  inn?: string
}

export interface UserActivateDto {
  /** Количество подписчиков в инстаграм */
  followersCount?: number
  /** Средний охват в инстаграм */
  coveragesCount?: number
  /** Количество подписчиков в инстаграм */
  passportVerif?: boolean
  /**
   * Тип заказа - "ACTIVATED" | "NOT_ACTIVATED" | "INCOMPLETE_DATA" | "CANCELED"
   * @default "NOT_ACTIVATED"
   */
  status?: string
}

export interface PaginateResultDto {
  count: number
  total: number
  data: string[]
}

export interface UserListResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  avatar?: string
  phone: string
  firstName: string
  status: string
  role: string
  instagramLink?: string
  coveragesCount?: number
  followersCount?: number
  topics?: string[]
  inn?: string
  orders?: number
  products?: number
}

export interface VerifyInfoResponseDto {
  /** Id */
  id: number
  /** Видео статистики за 7,14,30 дней */
  videoStatistics: string
  /** Скриншот статистики возраста и пола аудитории */
  genderStatistics: string
  /** Скриншот статистики по городам */
  countriesStatistics: string
  /** Скриншот статистики  последней истории */
  lastStoryStatistics: string
  /** Скриншот статистики последнего поста */
  publicationStatistics: string
}

export interface BlogerResponseDto {
  /** Id */
  id: number
  /** Ссылка на инстаграмм */
  instagramLink: string
  /** Город проживания */
  city: string
  /** Страна */
  country: string
  /** Количество подписчиков */
  followersCount: number
  /** Количество охватов */
  coveragesCount: number
  /** Информация по банковским данным */
  bankDetails: string[]
  /** Тематики */
  topics: string[]
  /** Информация по банковским данным */
  verifyInfo: VerifyInfoResponseDto
  /** Информация по банковским данным */
  orders: string[]
}

export interface CustonemerResponseDto {
  /** Id */
  id: number
  /** ИНН заказчика */
  inn?: string
  /** Актуальный баланс */
  total?: number
  /** количество продуктов */
  prductsCount?: number
  /** Количество заказов */
  orderCount?: number
  /** Товары заказчика(с пагинацией) */
  products?: string[]
  /** Заказы заказчика(с пагинацией) */
  orders?: string[]
}

export interface UserResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** avatar */
  avatar: string
  /** Роль */
  role: string
  /** Имя пользователя */
  firstName: string
  /** Фамилия пользователя */
  lastName: string
  /** Номер телефона пользователя */
  phone: string
  /** Телеграм username */
  tgUsername: string
  /** Статус состояния пользователя */
  status: string
  /** Информация блогера */
  bloger?: BlogerResponseDto
  /** Информация заказчика */
  customer?: CustonemerResponseDto
}

export interface CreateTransactionDto {
  /** ID заказчика */
  customerId: number
  /** Предыдущий баланс */
  previousBalance: number
  /** Актуальный баланс */
  total: number
}

export interface BlackListDto {
  /** Id пользователя для блокировки. Поле необязательное */
  userId?: number
  /** Ссылка на инстаграмм блоггера */
  instagramLink?: string
  /** Номер телефона пользователя */
  phone?: string
  /** Номер телефона пользователя для банковских переводов */
  accountPhone?: string
  /** Номер банковского счета пользователя */
  accountNumber?: string
  /** Описание причины блокировки */
  comment: string
}

export interface BlacklistResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Ссылка на инстаграмм блоггера */
  instagramLink?: string
  /** Номер телефона пользователя */
  phone?: string
  /** Номер телефона пользователя для банковских переводов */
  accountPhone?: string
  /** Номер банковского счета пользователя */
  accountNumber?: string
  /** Описание причины блокировки */
  comment: string
  /** Id пользователя для блокировки */
  userId?: number
}

export interface MyOrderListByCustomerResponseDto {
  /** Id */
  id: number
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Название товара */
  title?: string
  /** Статус заказа */
  status?: string
  /** Тип сбора заказа */
  collection?: string
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  /** Сколько на каком статусе людей */
  customReportersStatistic?: object
}

export interface BlogerOrderResponseDto {
  /** Id */
  id: number
  /** Аватар */
  avatar?: string
  /** Имя блогера */
  firstName?: string
  /** Тематики блогера */
  topics?: string[]
  /** Ссылка на инсту */
  instagramLink?: string
  /** Охват */
  coveragesCount?: number
  /** Количество подписчиков */
  followersCount?: number
  /** Id отклика */
  reportId?: number
  /** Статус отклика */
  reportStatus?: string
}

export interface OrderByCustomerResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Ссылка на товар */
  productLink?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  productId: number
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Ключевое слово товара */
  keyWord?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Статус заказа */
  status?: string
  /** Тип заказа */
  type?: string
  /** Название товара */
  title?: string
  /** Тип сбора заказа */
  collection?: string
  /** Id */
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Статистика по откликам */
  customReportersStatistic?: object
  /** Блогеры */
  blogers?: BlogerOrderResponseDto
}

export interface OrderResponsesResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  blogerId: number
  orderId: number
  filterPrice: string
  productKey: string
  myDeliveries: string
  myPurchases: string
  shtrihCode: string
  comment: string
  realsLink: string
  /** @format date-time */
  realsDate: string
  storisLink: string
  storyStatistics: string
  isCompleted: boolean
  isPaid: boolean
  collection: string
  status: string
  keyWord: string
  commentIntegration: string
  commentMarketplace: string
  photoUrl: string
  title: string
  article: string
  price: number
}

export interface CreateTopicDto {
  /** Название темы */
  name: string
}

export interface TopicResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Наименование тематики */
  name: string
}

export interface ProductVerificationDto {
  /** Перечисление id товаров, для подтверждения */
  approval?: number[]
  /** Перечисление id товаров, для отмены верификации */
  disapproval?: number[]
}

export interface ProductListResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Ссылка на товар */
  marketplaceUrl?: string
  /** Ссылка на фото */
  photoUrl?: string
  /** Наименование товара */
  title?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Верификация */
  status?: string
  /** Актуальность товара */
  isActual?: boolean
  /** Id заказчика */
  customerId?: number
  /** Имя заказчика */
  customerName?: string
}

export interface ProductResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Артикул товара */
  article: string
  /** Id маркетплейса */
  marketplaceId: number
  /** Наименование товара */
  title: string
  /** Описание товара */
  description: string
  /** Цена товара */
  price: number
  /** Актуальность товара */
  isActual: boolean
  /** Статус верификации товара */
  isVerified: string
  /** Id Заказчика */
  customerId: number
  /** Ссылка на товар */
  marketplaceUrl: string
  /** Ссылка на фото товара */
  photoUrl: string
  /** Денежные транзакции */
  topics: string[]
}

export interface UpdateProductDto {
  /** Ссылка на товар */
  marketplaceUrl?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Наименование товара */
  title?: string
  /** Описание товара */
  description?: string
  /** Артикул товара */
  article?: string
  /** Цена на товар */
  price?: number
  /** id маркетплейса */
  marketplaceId?: number
  /** Перечисление тем для заказов */
  topics?: Topic[]
}

export interface CreateMarketplaceDto {
  name: string
}

export interface MarketplaceResponseDto {
  /** Id */
  id: number
  name: string
}

export interface UpdateOrderResponseDto {
  orderScreenshot: string
  orderVideo: string
  screenshotMyPurchases: string
  screenshotShtrihcode: string
  adDate: string
  reviewsScreenshot: string
  realsLink: string
  storiesScreenshot: string
  statisticsScreenshot: string
}

export interface CreateOrderDto {
  /**
   * Сборка ("AUTOMATIC" | "MANUAL")
   * @default "AUTOMATIC"
   */
  collection?: string
  /** Максимальное количество участвующих блогеров */
  maxParticipants?: number
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Ключевое слово */
  keyWord: string
  /**
   * Повторное участие в продвижении
   * @default false
   */
  rePromotion?: boolean
  /**
   * Повторное участие
   * @default false
   */
  reParticipation?: boolean
  /** id продукта */
  productId: number
}

export interface OrderListResponseDto {
  /** Id */
  id: number
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Название товара */
  title?: string
  /** Цена товара */
  price?: number
}

export interface OrderByBlogerReportResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Ссылка на товар */
  productLink?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  productId: number
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Ключевое слово товара */
  keyWord?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Статус заказа */
  status?: string
  /** Тип заказа */
  type?: string
  /** Название товара */
  title?: string
  /** Тип сбора заказа */
  collection?: string
  /** Id */
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Ссылка на фото товара */
  step?: number
  /** Информация по откликам */
  customReporters?: object
}

export interface OrderResponsesListResponseDto {
  /** Id */
  id: number
  /**
   * Create at
   * @format date-time
   */
  createAt: string
  /**
   * Update at
   * @format date-time
   */
  updateAt: string
  /** Ссылка на товар */
  productLink?: string
  /** Максимальное количесво токенов */
  maxParticipants?: number
  /** Комментарий к заказу */
  commentIntegration?: string
  /** Комментарий маркетплейса */
  commentMarketplace?: string
  /** Ссылка на фото товара */
  photoUrl?: string
  /** Ключевое слово товара */
  keyWord?: string
  /** Цена товара */
  price?: number
  /** Артикул товара */
  article?: string
  /** Статус заказа */
  status?: string
  /** Тип заказа */
  type?: string
  /** Название товара */
  title?: string
  /** Тип сбора заказа */
  collection?: string
  /** Id */
  marketplaceId?: number
  /** Тип сбора заказа */
  marketplaceName?: string
  /** Максимальное количесво токенов */
  customReporters?: object
}

export interface PaymentReportDto {
  /** Скриншот оплаты */
  paymentScreenshot: string
}

export interface ResponsesManagementDto {
  /** Id`s одобренных блогеров */
  confirmed?: string[]
  /** Id`s отклоненных блогеров */
  rejected?: string[]
}

export interface OrderManagementDto {
  /** Статус заказа */
  status: string
}

export interface CreateProductDto {
  /** Ссылка на товар */
  marketplaceUrl: string
  /** Ссылка на фото товара */
  photoUrl: string
  /** Наименование товара */
  title: string
  /** Описание товара */
  description: string
  /** Артикул товара */
  article: string
  /** Цена на товар */
  price: number
  /** id маркетплейса */
  marketplaceId: number
  /** Перечисление тем для заказов */
  topics: Topic[]
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title Admin API
 * @version 1.0.0
 * @contact
 *
 * Описание API, предназначенного для обеспечения функциональности приложения системного администрирования.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignUpDto
     * @summary Эндпоинт предназначен для регистрации пользователей
     * @request POST:/api/auth/sign-up
     */
    authControllerSignUpDto: (data: SignUpDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse>({
        path: `/api/auth/sign-up`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSignInDto
     * @summary Эндпоинт предназначен для входа пользователей в ситему
     * @request POST:/api/auth/sign-in
     */
    authControllerSignInDto: (data: SignInDto, params: RequestParams = {}) =>
      this.request<SignInResponseDto, CommonMessageResponse>({
        path: `/api/auth/sign-in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blogers
     * @name BloggerControllerUpdate
     * @summary Эндпоинт служит для обновления существующего блогера
     * @request PATCH:/api/blogger
     * @secure
     */
    bloggerControllerUpdate: (data: UpsertBloggerDto, params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/api/blogger`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Blogers
     * @name BloggerControllerGetProfile
     * @summary Эндпоинт возвращает информацию профиля текущего блогера
     * @request GET:/api/blogger/profile
     * @secure
     */
    bloggerControllerGetProfile: (params: RequestParams = {}) =>
      this.request<any, void>({
        path: `/api/blogger/profile`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CustomersControllerFindOne
     * @summary Эндпоинт предназначен для получения информации о профиле Заказчика.
     * @request GET:/api/customers/profile
     * @secure
     */
    customersControllerFindOne: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/api/customers/profile`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customers
     * @name CustomersControllerUpdate
     * @summary Эндпоинт позволяет заказчикам обновлять свою информацию.
     * @request PATCH:/api/customers
     * @secure
     */
    customersControllerUpdate: (data: UpdateCustomerDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/customers`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerUserActivated
     * @summary Эндпоинт для активации пользователя
     * @request POST:/api/management/users/{userId}
     * @secure
     */
    managementControllerUserActivated: (userId: number, data: UserActivateDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/users/${userId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetUserById
     * @summary Эндпоинт для получения информации по пользователю
     * @request GET:/api/management/users/{userId}
     * @secure
     */
    managementControllerGetUserById: (
      userId: number,
      query?: {
        /**
         * Number page
         * @default 1
         */
        productPage?: number
        /**
         * Count
         * @default 20
         */
        productTake?: number
        /**
         * Number page
         * @default 1
         */
        ordersPage?: number
        /**
         * Count
         * @default 20
         */
        ordersTake?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResponseDto, void | CommonMessageResponse>({
        path: `/api/management/users/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetAllUsers
     * @summary Эндпоинт для получения списка пользователей
     * @request GET:/api/management/users
     * @secure
     */
    managementControllerGetAllUsers: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Тип заказа - "ACTIVATED" | "NOT_ACTIVATED" | "INCOMPLETE_DATA" | "CANCELED" */
        status?: string
        /** Роли участников - "ADMIN" | "BLOGER" | "CUSTOMER" | "MODERATOR" */
        role?: string
        /** Заполненность данных для блогера */
        isFullInfo?: boolean
        /** Строка с поисковым значением(инста или имя) */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: UserListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerSetCustomerBalance
     * @summary Метод для присвоения баланса заказчику
     * @request POST:/api/management/customer-balance
     * @secure
     */
    managementControllerSetCustomerBalance: (data: CreateTransactionDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/customer-balance`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerPushToBlacklist
     * @summary Метод для добавления  в черный список по Id либо по информации
     * @request POST:/api/management/blacklist
     * @secure
     */
    managementControllerPushToBlacklist: (data: BlackListDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/blacklist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetBlacklist
     * @summary Метод для получения черного списка
     * @request GET:/api/management/blacklist
     * @secure
     */
    managementControllerGetBlacklist: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Поле поиска. Возможен поиска по комментариям, инстаграмму */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: BlacklistResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/blacklist`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetAllOrders
     * @summary Метод для получения всех заказов
     * @request GET:/api/management/orders
     * @secure
     */
    managementControllerGetAllOrders: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /**
         * Ключевое слово, по которому можно найти товар.
         * @default "desc"
         */
        keyWord?: string
        /** Минимальный предел количества интеграций */
        minParticipants?: number
        /** Максимальный предел количества интеграций */
        maxParticipants?: number
        /**
         * Начало периода поиска
         * @format date-time
         */
        startDate?: string
        /**
         * Окончания периода поиска
         * @format date-time
         */
        finishDate?: string
        /** Признак подбора пользователей "AUTOMATIC" или "MANUAL" */
        collection?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: MyOrderListByCustomerResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerGetOrderById
     * @summary Метод для получения заказа по ID
     * @request GET:/api/management/orders/{orderId}
     * @secure
     */
    managementControllerGetOrderById: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderByCustomerResponseDto, void | CommonMessageResponse>({
        path: `/api/management/orders/${orderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerCancelOrder
     * @summary Метод отмены заказа
     * @request GET:/api/management/orders/{orderId}/cancel
     * @secure
     */
    managementControllerCancelOrder: (orderId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/orders/${orderId}/cancel`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindByIdResponses
     * @summary Метод для просмотра прогресса выполнения заданий(информация по отклику)
     * @request GET:/api/management/report/{reportId}
     * @secure
     */
    managementControllerFindByIdResponses: (reportId: number, params: RequestParams = {}) =>
      this.request<OrderResponsesResponseDto, void | CommonMessageResponse>({
        path: `/api/management/report/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerAddTopic
     * @summary Метод для добавления тематики
     * @request POST:/api/management/topics
     * @secure
     */
    managementControllerAddTopic: (data: CreateTopicDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/topics`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindAllTopics
     * @summary Метод для получения всех тематик
     * @request GET:/api/management/topics
     * @secure
     */
    managementControllerFindAllTopics: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Поле поиска */
        name?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: TopicResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/topics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerRemoveTopic
     * @summary Метод для удаления тематики
     * @request DELETE:/api/management/topics/{id}
     * @secure
     */
    managementControllerRemoveTopic: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/topics/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerVerificationProduct
     * @summary  Метод для верификации продукта
     * @request POST:/api/management/products
     * @secure
     */
    managementControllerVerificationProduct: (data: ProductVerificationDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindAllProducts
     * @summary Метод для получения всех продуктов
     * @request GET:/api/management/products
     * @secure
     */
    managementControllerFindAllProducts: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /** Актуальность товара */
        isActual?: boolean
        /** Статус товара */
        status?: string
        /** Строка с поисковым значением(Артикул, наименование или имя заказчика) */
        search?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: ProductListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/management/products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindProductById
     * @request GET:/api/management/products/{id}
     * @secure
     */
    managementControllerFindProductById: (id: number, params: RequestParams = {}) =>
      this.request<ProductResponseDto, void | CommonMessageResponse>({
        path: `/api/management/products/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerSetProductById
     * @request PATCH:/api/management/products/{id}
     * @secure
     */
    managementControllerSetProductById: (id: number, data: UpdateProductDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/management/products/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerCreateMarketplace
     * @summary Метод для добавления маркетплейса
     * @request POST:/api/management/marketplace
     * @secure
     */
    managementControllerCreateMarketplace: (data: CreateMarketplaceDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/marketplace`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerFindAllMarketplace
     * @summary Метод для получения всех маркетплейсов
     * @request GET:/api/management/marketplace
     * @secure
     */
    managementControllerFindAllMarketplace: (params: RequestParams = {}) =>
      this.request<MarketplaceResponseDto, void | CommonMessageResponse>({
        path: `/api/management/marketplace`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name ManagementControllerRemoveMarketplace
     * @summary Метод для удаления маркетплейса
     * @request DELETE:/api/management/marketplace/{id}
     * @secure
     */
    managementControllerRemoveMarketplace: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/management/marketplace/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Marketplace
     * @name ManagementControllerFindAllMarketplace2
     * @summary Эндпоинт для получения всех доступных маркетплейсов
     * @request GET:/api/marketplace
     * @originalName managementControllerFindAllMarketplace
     * @duplicate
     * @secure
     */
    managementControllerFindAllMarketplace2: (params: RequestParams = {}) =>
      this.request<MarketplaceResponseDto, void | CommonMessageResponse>({
        path: `/api/marketplace`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerCreate
     * @summary Метод для создания отклика на заказ
     * @request POST:/api/orders/{orderId}/responses
     * @secure
     */
    orderResponsesControllerCreate: (orderId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindAllResponses
     * @summary Метод для получения всех откликов по заказу
     * @request GET:/api/orders/{orderId}/responses
     * @secure
     */
    ordersControllerFindAllResponses: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderResponsesListResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerPaymentConfirm
     * @summary Метод для подтверждения платежа
     * @request POST:/api/orders/{orderId}/responses/{reportId}/payment-confirm
     * @secure
     */
    orderResponsesControllerPaymentConfirm: (orderId: number, reportId: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses/${reportId}/payment-confirm`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerSteps
     * @summary Метод для изменения шага отклика
     * @request PATCH:/api/orders/responses/{id}/{step}
     * @secure
     */
    orderResponsesControllerSteps: (
      id: number,
      step: number,
      data: UpdateOrderResponseDto,
      params: RequestParams = {},
    ) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}/${step}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Order responses
     * @name OrderResponsesControllerRemove
     * @summary  Метод для удаления отклика
     * @request DELETE:/api/orders/responses/{id}
     * @secure
     */
    orderResponsesControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/responses/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerCreate
     * @summary Метод для создания нового заказа
     * @request POST:/api/orders
     * @secure
     */
    ordersControllerCreate: (data: CreateOrderDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindAllOrders
     * @summary Метод для получения всех заказов
     * @request GET:/api/orders
     * @secure
     */
    ordersControllerFindAllOrders: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /**
         * Ключевое слово, по которому можно найти товар.
         * @default "desc"
         */
        keyWord?: string
        /** Минимальный предел количества интеграций */
        minParticipants?: number
        /** Максимальный предел количества интеграций */
        maxParticipants?: number
        /**
         * Начало периода поиска
         * @format date-time
         */
        startDate?: string
        /**
         * Окончания периода поиска
         * @format date-time
         */
        finishDate?: string
        /** Признак подбора пользователей "AUTOMATIC" или "MANUAL" */
        collection?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: OrderListResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/orders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindAll
     * @summary Метод для получения моих заказов
     * @request GET:/api/orders/my
     * @secure
     */
    ordersControllerFindAll: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        /**
         * Ключевое слово, по которому можно найти товар.
         * @default "desc"
         */
        keyWord?: string
        /** Минимальный предел количества интеграций */
        minParticipants?: number
        /** Максимальный предел количества интеграций */
        maxParticipants?: number
        /**
         * Начало периода поиска
         * @format date-time
         */
        startDate?: string
        /**
         * Окончания периода поиска
         * @format date-time
         */
        finishDate?: string
        /** Признак подбора пользователей "AUTOMATIC" или "MANUAL" */
        collection?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: MyOrderListByCustomerResponseDto[]
        },
        void | CommonMessageResponse
      >({
        path: `/api/orders/my`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindOne
     * @summary Метод для получения заказа по Id
     * @request GET:/api/orders/{id}
     * @secure
     */
    ordersControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<OrderByCustomerResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindOneBlogerReport
     * @summary Метод для получения моего заказа по Id
     * @request GET:/api/orders/my/{orderId}
     * @secure
     */
    ordersControllerFindOneBlogerReport: (orderId: number, params: RequestParams = {}) =>
      this.request<OrderByBlogerReportResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/my/${orderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerSetReportPayment
     * @summary Метод для добовления отчености по оплате
     * @request POST:/api/orders/{orderId}/responses/{reportId}
     * @secure
     */
    ordersControllerSetReportPayment: (
      reportId: number,
      orderId: number,
      data: PaymentReportDto,
      params: RequestParams = {},
    ) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses/${reportId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerFindByIdResponses
     * @summary Метод для получения отклика по ID
     * @request GET:/api/orders/{orderId}/responses/{reportId}
     * @secure
     */
    ordersControllerFindByIdResponses: (reportId: number, orderId: number, params: RequestParams = {}) =>
      this.request<OrderResponsesResponseDto, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses/${reportId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerResponsesManagement
     * @summary  Метод для управления откликами на заказ
     * @request PATCH:/api/orders/{orderId}/responses-management
     * @secure
     */
    ordersControllerResponsesManagement: (orderId: number, data: ResponsesManagementDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/responses-management`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name OrdersControllerChangeOrderStatus
     * @summary Метод для изменения статуса заказа
     * @request PATCH:/api/orders/{orderId}/management
     * @secure
     */
    ordersControllerChangeOrderStatus: (orderId: number, data: OrderManagementDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/orders/${orderId}/management`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerCreate
     * @summary Метод для создания товара
     * @request POST:/api/products
     * @secure
     */
    productsControllerCreate: (data: CreateProductDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerFindAll
     * @summary Метод для получения всех товаров
     * @request GET:/api/products
     * @secure
     */
    productsControllerFindAll: (
      query: {
        /**
         * Number page
         * @default 1
         */
        page: number
        /**
         * Count
         * @default 50
         */
        take: number
        /** @default "asc" */
        createAt?: string
        isActual?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<
        PaginateResultDto & {
          data?: ProductResponseDto[]
        },
        CommonMessageResponse | void
      >({
        path: `/api/products`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerFindOne
     * @summary Метод для получения одного товара
     * @request GET:/api/products/{id}
     * @secure
     */
    productsControllerFindOne: (id: number, params: RequestParams = {}) =>
      this.request<ProductResponseDto, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerUpdate
     * @summary Метод для обновления товара
     * @request PATCH:/api/products/{id}
     * @secure
     */
    productsControllerUpdate: (id: number, data: UpdateProductDto, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsControllerRemove
     * @summary Метод для удаления товара
     * @request DELETE:/api/products/{id}
     * @secure
     */
    productsControllerRemove: (id: number, params: RequestParams = {}) =>
      this.request<CommonMessageResponse, void | CommonMessageResponse>({
        path: `/api/products/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Topics
     * @name TopicsControllerFindAll
     * @summary Метод для получения всех тематик
     * @request GET:/api/topics
     * @secure
     */
    topicsControllerFindAll: (params: RequestParams = {}) =>
      this.request<CommonMessageResponse, CommonMessageResponse | void>({
        path: `/api/topics`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
}
