import React, { FC, ReactNode } from 'react'
import cn from 'classnames'

import styles from './Field.module.scss'

export interface FieldPropsType {
  className?: string
  // TODO Подумать нужен ли smallCabinet? Используется только в одном месте, возможно можно просто стилями его заменить
  kind?: 'landing' | 'cabinet' | 'smallCabinet'
  label?: string
  id: string
  error?: string
  description?: string
  children: ReactNode
  isRequired?: boolean
  withHiddenLabel?: boolean
}

export const Field: FC<FieldPropsType> = ({ className, kind = 'landing', label, id, error, description, children, isRequired, withHiddenLabel }) => {
  return (
    <div className={cn(
      styles.Field,
      kind === 'landing' && styles.Field__landing,
      kind === 'cabinet' && styles.Field__cabinet,
      kind === 'smallCabinet' && styles.Field__smallCabinet,
      className
    )}>
      {!withHiddenLabel && <label
        className={styles.Label} htmlFor={id}>
        {label}
        {isRequired && <span className={styles.Star}>*</span>}
      </label>}
      {children}
      {error && <span className={styles.Error}>{error}</span>}
      {description && <span className={styles.Description}>{description}</span>}
    </div>
  )
}
