import React, { FC } from 'react'
import { Accordion } from 'src/shared/ui/Accordion'

import styles from './CabinetFAQ.module.scss'
import { QUESTIONS } from '../../config/consts'

export const CabinetFAQ: FC = () => {
  return (
    <div className={styles.FAQ}>
      <h2 className={styles.Title}>Частые вопросы</h2>
      <Accordion kind="cabinet" data={QUESTIONS} />
    </div>
  )
}
