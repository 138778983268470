import React, { ChangeEventHandler, FC, FormEvent, useCallback, useState } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { Flex } from 'src/shared/ui/Flex'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AdminAPI } from 'src/shared/api'
import { Input } from 'src/shared/ui/Input'
import { Icon } from 'src/shared/ui/Icon'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { useMarketplaces } from 'src/shared/model/useMarketplaces'

import styles from './AdminCabinetMarketplaces.module.scss'

export const AdminCabinetMarketplaces: FC = () => {
  const [isAddNewMarketplace, setIsAddNewMarketplace] = useState(false)
  const [newMarketplaceName, setNewMarketplaceName] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()

  const queryClient = useQueryClient()

  const marketplaces = useMarketplaces()

  // @ts-ignore
  const onError = useCallback((error: Error) => setErrorMessage(error?.error?.message), [])

  const { mutate: addMarketplaceMutation, isPending: isAddMarketplaceLoading } = useMutation({
    mutationFn: AdminAPI.api.managementControllerCreateMarketplace,
    onSuccess: () => {
      setIsAddNewMarketplace(false)
      setNewMarketplaceName('')
      setErrorMessage(undefined)
      queryClient.invalidateQueries({ queryKey: ['marketplaces'] })
    },
    onError
  })

  const { mutate: removeMarketplaceMutation } = useMutation({
    mutationFn: AdminAPI.api.managementControllerRemoveMarketplace,
    onSuccess: () => {
      setErrorMessage(undefined)
      queryClient.invalidateQueries({ queryKey: ['marketplaces'] })
    },
    onError
  })

  const addMarketplace = useCallback(() => {
    if (!newMarketplaceName) {
      return
    }

    addMarketplaceMutation({ name: newMarketplaceName })
  }, [newMarketplaceName, addMarketplaceMutation])

  const handleNewMarketplaceNameInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewMarketplaceName(event.target.value)
    setErrorMessage(undefined)
  }, [])

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    addMarketplace()
  }, [addMarketplace])

  const handleAddButtonClick = useCallback(() => setIsAddNewMarketplace(true), [])

  const handleCancelButtonClick = useCallback(() => {
    setIsAddNewMarketplace(false)
    setNewMarketplaceName('')
  }, [])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <h1 className={styles.Title}>Маркетплейсы</h1>
      <Flex className={styles.Marketplaces} flexDirection="column" gap={8}>
        {marketplaces.map(item => (
          <Flex key={item.name} alignItems="center" gap={20}>
            <Input
              kind="cabinet"
              value={item.name}
              readOnly
            />
            <Icon
              className={styles.CloseIcon}
              name="close"
              onClick={() => removeMarketplaceMutation(item.id)}
            />
          </Flex>
        ))}
        {isAddNewMarketplace ? (
          <form onSubmit={handleSubmit}>
            <Flex alignItems="center" gap={20}>
              <Input
                kind="cabinet"
                value={newMarketplaceName}
                onChange={handleNewMarketplaceNameInputChange}
                disabled={isAddMarketplaceLoading}
                autoFocus
              />
              <Icon
                className={styles.CloseIcon}
                name="close"
                onClick={handleCancelButtonClick}
              />
            </Flex>
          </form>
        ) : (
          <CabinetActionButton
            className={styles.AddButton}
            kind="ghost"
            onClick={handleAddButtonClick}
          >
            Добавить маркетплейс
          </CabinetActionButton>
        )}
        {errorMessage && <span className={styles.Error}>{errorMessage}</span>}
      </Flex>
    </CabinetPageLayout>
  )
}
