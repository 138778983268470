import React, { FC, useCallback } from 'react'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { useConfirmPaymentMutation } from '../../api/useConfirmPaymentMutation'
import { Media } from 'src/shared/ui/Media'
import { Section } from '../Section/Section'
import { Block } from '../Block/Block'
import { ValueField } from 'src/shared/ui/ValueField'

import styles from './WaitPayConfirmStage.module.scss'

interface WaitPayConfirmStagePropsType {
  orderId: number
  responseId: number
  paymentScreenshot: string
}

export const WaitPayConfirmStage: FC<WaitPayConfirmStagePropsType> = ({ orderId, responseId, paymentScreenshot }) => {
  const { mutate: confirmPayment, isPending: isLoading } = useConfirmPaymentMutation(orderId, responseId)

  const handleButtonClick = useCallback(() => confirmPayment(), [confirmPayment])

  return (
    <Section title="Ожидание оплаты от селлера">
      <Flex flexDirection="column">
        <Block className={styles.Block}>
          <ValueField label="Дождитесь, пока селлер перевед вам полную стоимость оплаты за задание." />
          <ValueField label="После получения средств нажмите кнопку “Оплата получена”." />
          <Media
            className={styles.PaymentScreenshot}
            desciption="Скриншот оплаты"
            type="image"
            src={paymentScreenshot}
          />
        </Block>
        <CabinetButton className={styles.CabinetButton} onClick={handleButtonClick} disabled={isLoading}>
          Оплата получена
        </CabinetButton>
      </Flex>
    </Section>
  )
}
