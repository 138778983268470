import React, { FC } from 'react'
import cn from 'classnames'
import { NumericInput, NumericInputPropsType } from '../NumericInput'

import styles from './PhoneInput.module.scss'

export type PhoneInputPropsType = NumericInputPropsType

export const PhoneInput: FC<PhoneInputPropsType> = ({ className, kind = 'landing', isInvalid, ...rest }) => {
  return (
    <div className={cn(
      styles.PhoneInput,
      isInvalid && styles.isInvalid,
      kind === 'landing' && styles.PhoneInput__landing,
      (kind === 'cabinet' || kind === 'smallCabinet') && styles.PhoneInput__cabinet,
      className
    )}>
      <div className={styles.Code}>+7</div>
      <NumericInput className={styles.NumericInput} kind={kind} isInvalid={isInvalid} {...rest}/>
    </div>
  )
}
