import React, { FC, useCallback, useState } from 'react'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Field } from 'src/shared/ui/Field'
import { Uploader } from 'src/shared/ui/Uploader'
import { useUpload } from 'src/shared/model/useUpload'
import { UpdateOrderResponseDto } from 'src/shared/api'
import { useUpdateOrderResponseMutation } from '../../api/useUpdateOrderResponseMutation'
import { TextInputField } from 'src/shared/ui/TextInputField'
import { Section } from '../Section/Section'
import { ValueField } from 'src/shared/ui/ValueField'
import { Block } from '../Block/Block'

import styles from './CompletedSecondStepStage.module.scss'

interface CompletedSecondStepStagePropsType {
  orderId: number
  responseId: number
}

export const CompletedSecondStepStage: FC<CompletedSecondStepStagePropsType> = ({ orderId, responseId }) => {
  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const [realsLink, setRealsLink] = useState<string>()
  const [reviewsScreenshotImage, setReviewsScreenshotImage] = useState<File>()
  const [storiesScreenshotImage, setStoriesScreenshotImage] = useState<File>()
  const [statisticsScreenshotImage, setStatisticsScreenshotImage] = useState<File>()

  const {
    mutate: updateOrderResponse,
    isPending: isUpdateOrderResponseLoading
  } = useUpdateOrderResponseMutation(orderId, responseId)

  const isLoading = isUpdateOrderResponseLoading || isUploading

  const isButtonDisabled =
    isLoading ||
    !reviewsScreenshotImage ||
    !realsLink ||
    !storiesScreenshotImage ||
    !statisticsScreenshotImage

  const handleButtonClick = useCallback(async () => {
    try {
      setIsUploading(true)
      const reviewsScreenshot = await upload(reviewsScreenshotImage!)
      const storiesScreenshot = await upload(storiesScreenshotImage!)
      const statisticsScreenshot = await upload(statisticsScreenshotImage!)
      setIsUploading(false)

      await updateOrderResponse({
        step: 3,
        data: {
          reviewsScreenshot,
          realsLink,
          storiesScreenshot,
          statisticsScreenshot,
        } as UpdateOrderResponseDto
      })
    } catch (e) {
      console.error(e)
      setIsUploading(false)
    }
  }, [reviewsScreenshotImage, realsLink, storiesScreenshotImage, statisticsScreenshotImage, upload])

  return (
    <Section title="3 раздел. Реклама">
      <Flex flexDirection="column">
        <Block className={styles.Block}>
          <ValueField label="Инструкция:">
            ТЕКСТ ИНСТРУКЦИИ*
          </ValueField>
          <Field id="image" label="Прикрепите скрин отзыва:" kind="cabinet">
            <Uploader
              type="image"
              file={reviewsScreenshotImage}
              onChange={setReviewsScreenshotImage}
              isDisabled={isLoading}
              placeholder="Загрузить скриншот"
            />
          </Field>
          <TextInputField
            id="realsLink"
            label="Прикрепите ссылку на рилс:"
            kind="cabinet"
            value={realsLink}
            onChange={(event) => setRealsLink(event.target.value)}
          />
          <Field id="image" label="Прикрепите скриншот сторис:" kind="cabinet">
            <Uploader
              type="image"
              file={storiesScreenshotImage}
              onChange={setStoriesScreenshotImage}
              isDisabled={isLoading}
              placeholder="Загрузить скриншот"
            />
          </Field>
          <Field id="image" label="Прикрепите скриншот статистики на сторис:" kind="cabinet">
            <Uploader
              type="image"
              file={statisticsScreenshotImage}
              onChange={setStatisticsScreenshotImage}
              isDisabled={isLoading}
              placeholder="Загрузить скриншот"
            />
          </Field>
        </Block>
        <CabinetButton
          className={styles.CabinetButton}
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
        >
          Отправить
        </CabinetButton>
      </Flex>
    </Section>
  )
}
