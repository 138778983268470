import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { FC, useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { AdminAPI, CommonMessageResponse, HttpResponse, PaymentReportDto } from 'src/shared/api'
import { OrderResponsesResponseDto } from 'src/shared/config/types'
import { useUpload } from 'src/shared/model/useUpload'
import { CabinetIntegrationPageLayout } from 'src/widgets/CabinetIntegrationPageLayout'

export const CabinetIntegration: FC = () => {
  const { taskId, responseId } = useParams()
  const orderId = Number(taskId)
  const reportId = Number(responseId)

  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const queryClient = useQueryClient()

  const { data: orderResponseData } = useQuery({
    queryKey: ['orders', orderId, reportId],
    queryFn: () => AdminAPI.api.ordersControllerFindByIdResponses(reportId, orderId),
  })
  const orderResponse = orderResponseData?.data as (OrderResponsesResponseDto | undefined)

  const { mutate: setOrderReportPaymentMutation, isPending } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    PaymentReportDto
  >({
    mutationFn: (data: PaymentReportDto) => AdminAPI.api.ordersControllerSetReportPayment(reportId, orderId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['orders', orderId, reportId] })
  })
  const isSendReportLoading = isPending || isUploading

  const handleSendReport = useCallback(async (image: File) => {
    try {
      setIsUploading(true)
      const paymentScreenshot = await upload(image)
      setIsUploading(false)

      setOrderReportPaymentMutation({ paymentScreenshot })
    } catch (e) {
      console.error(e)
      setIsUploading(false)
    }
  }, [setOrderReportPaymentMutation])

  if (!orderResponse) {
    return null
  }

  return <CabinetIntegrationPageLayout orderResponse={orderResponse} isSendReportLoading={isSendReportLoading} onSendReport={handleSendReport} />
}
