import React, { FC } from 'react'
import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { RoleType } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'

import styles from './RejectedBlogger.module.scss'

interface RejectedBloggerPropsType extends BloggerPropsType {
  role: RoleType
  onConfirmClick?(): void
}

export const RejectedBlogger: FC<RejectedBloggerPropsType> = ({ role, onConfirmClick, ...props }) => {
  return (
    <Blogger
      {...props}
    >
      {role === RoleType.CUSTOMER && (
        <Flex className={styles.Container} flexDirection="column" alignItems="center" gap={16}>
          <span className={styles.Text}>
            Участник был отклонен вами ранее, однако вы можете принять его если передумаете.
          </span>
          <CabinetActionButton onClick={onConfirmClick}>Подтвердить участника</CabinetActionButton>
        </Flex>
      )}
    </Blogger>
  )
}
