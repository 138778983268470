import React, { FC } from 'react'
import cn from 'classnames'

import styles from './Avatar.module.scss'

interface AvatarPropsType {
  className?: string
  avatar?: string
  firstName: string
  onClick?(): void
}

export const Avatar: FC<AvatarPropsType> = ({ className, avatar, firstName, onClick }) => {
  return (
    <div className={cn(styles.Avatar, className)} onClick={onClick}>
      {avatar ? (
        <img
          className={styles.Image}
          src={avatar}
        />
      ) : (
        <span className={styles.Chart}>
          {firstName[0]}
        </span>
      )}
    </div>
  )
}
