import React, { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { AdminAPI } from 'src/shared/api'
import { ProductForm } from '../ProductForm/ProductForm'

import styles from './AdminCabinetProduct.module.scss'

export const AdminCabinetProduct: FC = () => {
  const { productId: productIdParam } = useParams()
  const productId = Number(productIdParam)

  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(-1), [navigate])

  const { data: productData } = useQuery({
    queryKey: ['products', productId],
    queryFn: () => AdminAPI.api.managementControllerFindProductById(productId),
  })
  const product = productData?.data

  if (!product) {
    return null
  }

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <div className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>{product.title}</h1>
      </div>
      <ProductForm productId={productId} product={product} />
    </CabinetPageLayout>
  )
}
