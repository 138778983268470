import React, { FC, ReactNode } from 'react'
import { Avatar } from 'src/shared/ui/Avatar'
import cn from 'classnames'

import styles from './Blogger.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

export interface BloggerPropsType {
  firstName: string
  avatar?: string
  instagramLink?: string
  followersCount?: number
  coveragesCount?: number
  topics?: string[]
  children?: ReactNode
  onClick?(): void
}

export const Blogger: FC<BloggerPropsType> = ({ firstName, avatar, instagramLink, followersCount, coveragesCount, topics, onClick, children }) => {
  return (
    <div className={cn(styles.Blogger, onClick && styles.withPointerCursor)} onClick={onClick}>
      <div className={styles.Header}>
        <Avatar className={styles.Image} avatar={avatar} firstName={firstName} />
        <div className={styles.Info}>
          <span className={styles.Name}>{firstName}</span>
          <span className={styles.InstagramLink}>{instagramLink}</span>
        </div>
      </div>
      <div className={styles.Body}>
        <div className={styles.Item}>
          <span className={styles.Title}>Подписчики:</span>
          <span className={styles.Value}>{followersCount !== undefined ? numberFormat.format(followersCount) : '-'}</span>
        </div>
        <div className={styles.Item}>
          <span className={styles.Title}>Средний охват:</span>
          <span className={styles.Value}>{coveragesCount !== undefined ? numberFormat.format(coveragesCount) : '-'}</span>
        </div>
        <div className={styles.Item}>
          <span className={styles.Title}>Тематика:</span>
          {topics && topics.length > 0 ? (
            <div className={styles.Tags}>
              {topics.map(item => <div key={item} className={styles.Tag}>{item}</div>)}
            </div>)
            : '-'
          }
        </div>
      </div>
      {children}
    </div>
  )
}
