import { useQuery } from '@tanstack/react-query'
import React, { FC } from 'react'
import { useParams } from 'react-router'
import { AdminAPI, UserResponseDto } from 'src/shared/api'
import { RoleType, UserStatusType } from 'src/shared/config/types'
import { CustomerUser } from './CustomerUser/CustomerUser'
import { BlogerUser } from './BlogerUser/BlogerUser'
import { VerificationRequest } from './VerificationRequest/VerificationRequest'

export const AdminCabinetUser: FC = () => {
  const { userId: userIdParam } = useParams()
  const userId = Number(userIdParam)

  const { data: userData } = useQuery({
    queryKey: ['users', userId],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.managementControllerGetUserById(userId, { ordersPage: 1, ordersTake: 200, productPage: 1, productTake: 200 }),
  })

  const user = userData?.data as (UserResponseDto | undefined)

  if (!user) {
    return null
  }

  if (user.role === RoleType.CUSTOMER) {
    return <CustomerUser user={user} />
  }

  if (user.role === RoleType.BLOGER) {
    if (user.status === UserStatusType.NOT_ACTIVATED) {
      return <VerificationRequest user={user} />
    }

    if (user.status === UserStatusType.ACTIVATED) {
      return <BlogerUser user={user} />
    }
  }

  return null
}
