import React, { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { TextareaFormField } from 'src/shared/ui/TextareaFormField'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { CheckboxFormField } from 'src/shared/ui/CheckboxFormField'
import { AdminAPI, CommonMessageResponse, CreateOrderDto, HttpResponse } from 'src/shared/api'
import { ProductSelectFormField } from '../ProductSelectFormField'
import { CollectionSelectFormField } from '../CollectionSelectFormField/CollectionSelectFormField'
import { CollectionType } from 'src/shared/config/types'

import styles from './CabinetTasksNew.module.scss'

type CabinetTasksNewFormFields = CreateOrderDto

export const CabinetTasksNew: FC = () => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_TASKS_ROUTE), [navigate])

  const methods = useForm<CabinetTasksNewFormFields>()
  const { handleSubmit, watch, setError } = methods
  const values = watch()

  const createOrderMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    CreateOrderDto
  >({
    mutationFn: AdminAPI.api.ordersControllerCreate
  })
  const isLoading = createOrderMutation.isPending

  const onSubmit = useCallback((data: CabinetTasksNewFormFields) => {
    const createOrderDto: CreateOrderDto = {
      collection: data.collection,
      maxParticipants: Number(data.maxParticipants),
      commentMarketplace: data.commentMarketplace,
      commentIntegration: data.commentIntegration,
      keyWord: data.keyWord,
      rePromotion: data.rePromotion,
      reParticipation: data.reParticipation,
      productId: Number(data.productId),
    }

    if (createOrderDto.collection === CollectionType.MANUAL) {
      delete createOrderDto.maxParticipants
    }

    createOrderMutation.mutate(createOrderDto, {
      onSuccess: () => {
        navigate(CABINET_TASKS_ROUTE, { replace: true })
      },
      onError: (error) => {
        if (error.error) {
          setError('productId', error.error)
        }
      }
    })
  }, [createOrderMutation, navigate])

  const isButtonDisabled = useMemo(() =>
    !values.productId ||
    (values.collection === CollectionType.AUTOMATIC ? !values.maxParticipants : false) ||
    !values.collection ||
    !values.keyWord ||
    isLoading,
  [values, isLoading])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <div className={styles.Header}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>Создание задания</h1>
      </div>
      <FormProvider {...methods}>
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.InputsContainers}>
            <div className={styles.InputsContainer}>
              <ProductSelectFormField
                id="productId"
                name="productId"
                label="Выберите товар"
                placeholder="Выбрать товар из списка"
              />
              <CollectionSelectFormField
                id="collection"
                name="collection"
                label="Выберите тип сбора"
                placeholder="Выберите тип сбора"
              />
              {values.collection === CollectionType.AUTOMATIC && (
                <NumericInputFormField
                  id="maxParticipants"
                  name="maxParticipants"
                  label="Укажите количество участников"
                  placeholder="Количество участников"
                  kind="cabinet"
                />
              )}
              <div className={styles.Checkboxes}>
                <CheckboxFormField
                  id="rePromotion"
                  name="rePromotion"
                  description="Если функция включена на заказ смогут откликнутся участники из вашего последнего заказа"
                  withHiddenLabel
                >
                  Разрешить повторный отклик
                </CheckboxFormField>
                <CheckboxFormField
                  id="reParticipation"
                  name="reParticipation"
                  description="Разрешить участие блогерам, которые ранее участвовали в ваших заказах с этим артикулом товара"
                  withHiddenLabel
                >
                  Разрешить повторное участие
                </CheckboxFormField>
              </div>
            </div>
            <div className={styles.InputsContainer}>
              <TextareaFormField
                id="keyWord"
                name="keyWord"
                label="Ключевое слово для выкупа товара"
                placeholder="Укажите ключевое слово"
                kind="cabinet"
              />
              <TextareaFormField
                id="commentMarketplace"
                name="commentMarketplace"
                label="Требования к отзыву на маркетплейсе"
                placeholder="Опишите требования"
                kind="cabinet"
              />
              <TextareaFormField
                id="commentIntegration"
                name="commentIntegration"
                label="Требования к рекламной интеграции"
                placeholder="Опишите требования"
                kind="cabinet"
              />
            </div>
          </div>
          <CabinetButton className={styles.CabinetButton} disabled={isButtonDisabled}>
            Создать заказ
          </CabinetButton>
        </form>
      </FormProvider>
    </CabinetPageLayout>
  )
}
