import { useLocalStorage } from "react-use";
import { SignInUserResponseDto } from "../api"

export const useUserInfo = () => {
  const [accessToken] = useLocalStorage<string>('accessToken', undefined, { raw: true })
  const [user] = useLocalStorage<SignInUserResponseDto>('user')

  return {
    accessToken,
    user
  }
}
