import React, { FC } from 'react'
import { OrderResponsesResponseDto } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'
import { MediaSections } from '../MediaSections/MediaSections'

import styles from './WaitPayConfirmStage.module.scss'

interface WaitPayConfirmStagePropsType {
  orderResponse: OrderResponsesResponseDto
}

export const WaitPayConfirmStage: FC<WaitPayConfirmStagePropsType> = ({ orderResponse }) => {
  return (
    <Flex className={styles.WaitPayConfirmStage} flexDirection="column">
      <MediaSections orderResponse={orderResponse} />
      <div className={styles.Title}>
        Подтверждение оплаты
      </div>
    </Flex>
  )
}
