import React, { FC, MouseEvent, useCallback } from 'react'
import cn from 'classnames'
import { Flex } from 'src/shared/ui/Flex'
import { CollectionType, CustomerOrderListResponseDto, OrderStatusType } from 'src/shared/config/types'
import { Collection } from 'src/shared/ui/Collection'
import { OrderStatus } from 'src/shared/ui/OrderStatus'
import { СollectionButton } from 'src/shared/ui/СollectionButton'

import styles from './Task.module.scss'

interface TaskPropsType {
  task: CustomerOrderListResponseDto
  onClick(id: number): void
  onStatusChange?(id: number, status: OrderStatusType): void
}

export const Task: FC<TaskPropsType> = ({ task, onClick, onStatusChange }) => {
  const { photoUrl, title, collection, marketplaceName, status, maxParticipants, customReportersStatistic } = task

  const handleClick = useCallback(() => onClick(task.id), [task, onClick])

  const handleCollectionButtonClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onStatusChange?.(task.id, status === OrderStatusType.PAUSE ? OrderStatusType.IN_PROGRESS : OrderStatusType.PAUSE)
  }, [task, onStatusChange])

  const renderItem = useCallback((title: string, count: number, isWaitingForPayment = false) => (
    <Flex flexDirection="column" gap={8}>
      <span className={styles.CustomReportersTitle}>{title}</span>
      <span className={cn(styles.CustomReportersCount, isWaitingForPayment && styles.CustomReportersCount__isWaitingForPayment)}>{count}</span>
    </Flex>
  ), [])

  return (
    <Flex className={styles.Task} flexDirection="column" onClick={handleClick}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" gap={20}>
          <img className={styles.Photo} src={photoUrl} />
          <Flex flexDirection="column" gap={8}>
            <span className={styles.Title}>{title}</span>
            <Flex alignItems="center" gap={16}>
              <span className={styles.Marketplace}>{marketplaceName}</span>
              <Collection collection={collection!} />
            </Flex>
          </Flex>
        </Flex>
        <OrderStatus
          status={status!}
          collection={collection!}
          confirmedCount={customReportersStatistic!.CONFIRMED}
          maxParticipants={maxParticipants!}
        />
      </Flex>
      <Flex className={styles.Body} alignItems="center" gap={40}>
        {collection === CollectionType.MANUAL &&
          <>
            {renderItem('Отклонены:', customReportersStatistic!.REJECTED )}
            {renderItem('Откликнулись:', customReportersStatistic!.PENDING)}
          </>
        }
        {renderItem('Оформляют заказ:', customReportersStatistic!.CONFIRMED)}
        {renderItem('Ждут оплаты:', customReportersStatistic!.WAIT_PAYMENT, true)}
        {renderItem('Оплата отправлена:', customReportersStatistic!.WAIT_PAY_CONFIRM)}
        {renderItem('Выполняют задание:', customReportersStatistic!.PAID + customReportersStatistic!.COMPLETED_STEP_2)}
        {renderItem('Сдали отчет:', customReportersStatistic!.COMPLETED)}
        {onStatusChange && collection === CollectionType.MANUAL && (status === OrderStatusType.PAUSE || status === OrderStatusType.IN_PROGRESS) &&
          <СollectionButton
            className={styles.СollectionButton}
            status={status}
            onClick={handleCollectionButtonClick}
          />
        }
      </Flex>
    </Flex>
  )
}
