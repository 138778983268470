import React, { FC, useCallback } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { Flex } from 'src/shared/ui/Flex/Flex'
import { useQuery } from '@tanstack/react-query'
import { AdminAPI } from 'src/shared/api'
import { BloggerOrderListResponseDto } from 'src/shared/config/types'
import { Task } from '../Task/Task'
import { useNavigate } from 'react-router'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'

import styles from './BloggerCabinetTasks.module.scss'

export const BloggerCabinetTasks: FC = () => {
  const navigate = useNavigate()
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])

  const { data: ordersData } = useQuery({
    queryKey: ['orders', 'my'],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.ordersControllerFindAll({ page: 1, take: 200 }),
  })
  const orders = (ordersData?.data?.data ?? []) as BloggerOrderListResponseDto[]

  return (
    <CabinetPageLayout>
      <h1 className={styles.Title}>Мои задания</h1>
      <Flex className={styles.Tasks} flexDirection="column" gap={20}>
        {orders.map((item) => (
          <Task
            key={item.id}
            task={item}
            onClick={() => openTask(item.id)}
          />
        ))}
      </Flex>
    </CabinetPageLayout>
  )
}
