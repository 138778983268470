import React, { FC, useCallback, useMemo, useState } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { UserResponseDto } from 'src/shared/api'
import { CabinetBackButton } from 'src/shared/ui/CabinetBackButton'
import { useNavigate } from 'react-router'
import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'
import { Flex } from 'src/shared/ui/Flex'
import { Tabs } from 'src/shared/ui/Tabs'
import { Profile } from '../Profile/Profile'
import { Tasks } from '../Tasks/Tasks'

import styles from './BlogerUser.module.scss'

enum TabType {
  PROFILE = 'PROFILE',
  TASKS = 'TASKS',
}

interface BlogerUserPropsType {
  user: UserResponseDto
}

export const BlogerUser: FC<BlogerUserPropsType> = ({ user }) => {
  const navigate = useNavigate()
  const handleBackButtonClick = useCallback(() => navigate(CABINET_USERS_ROUTE), [navigate])

  const [activeTabId, setActiveTabId] = useState<string>(TabType.PROFILE)

  const tabs = useMemo(() => [
    {
      tabId: TabType.PROFILE,
      title: 'Личная информация',
    },
    {
      tabId: TabType.TASKS,
      title: 'Задания блогера',
    },
  ], [])

  return (
    <CabinetPageLayout>
      <Flex flexDirection="column" alignItems="flex-start" gap={16}>
        <CabinetBackButton onClick={handleBackButtonClick} />
        <h1 className={styles.Title}>Блогер</h1>
      </Flex>
      <Tabs
        className={styles.Tabs}
        activeTabId={activeTabId}
        tabs={tabs}
        onTabClick={setActiveTabId}
      />
      {activeTabId === TabType.PROFILE && (
        <Profile user={user} />
      )}
      {activeTabId === TabType.TASKS && (
        <Tasks user={user} />
      )}
    </CabinetPageLayout>
  )
}
