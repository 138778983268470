import React, { FC } from 'react'
import { Modal } from 'src/shared/ui/Modal'
import { Flex } from 'src/shared/ui/Flex'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { BloggerType } from 'src/shared/config/types'
import { Avatar } from 'src/shared/ui/Avatar'

import styles from './RejectModal.module.scss'

interface RejectModalPropsType {
  blogger: BloggerType
  onSubmit(): void
  onClose(): void
}

export const RejectModal: FC<RejectModalPropsType> = ({ blogger, onSubmit, onClose }) => {
  const { avatar, firstName, instagramLink } = blogger

  return (
    <Modal className={styles.RejectModal} title="Вы уверены, что хотите отклонить блоггера?" size="small" onClose={onClose}>
      <Flex flexDirection="column" gap={24}>
        <Flex gap={16}>
          <Avatar className={styles.Avatar} avatar={avatar} firstName={firstName}/>
          <Flex flexDirection="column" gap={4}>
            <span className={styles.FirstName}>{firstName}</span>
            <span className={styles.InstagramLink}>{instagramLink}</span>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap={8}>
          <CabinetActionButton kind="danger" onClick={onSubmit}>Да, отклонить</CabinetActionButton>
          <CabinetActionButton kind="ghost" onClick={onClose}>Нет, вернуться назад</CabinetActionButton>
        </Flex>
      </Flex>
    </Modal>
  )
}
