import React, { FC, InputHTMLAttributes, ReactNode } from 'react'
import cn from 'classnames'

import styles from './Input.module.scss'

export interface InputPropsType extends InputHTMLAttributes<HTMLInputElement> {
  kind?: 'landing' | 'cabinet' | 'smallCabinet'
  isInvalid?: boolean
  isRequired?: boolean
  postfix?: ReactNode
}

export const Input: FC<InputPropsType> = ({ className, kind = 'landing', isInvalid = false, isRequired, postfix, ...rest }) => {
  const withPostfix = postfix && kind === "cabinet"

  return (
    <label
      className={cn(
        styles.Container,
        isInvalid && styles.isInvalid,
        withPostfix && styles.withPostfix,
        kind === 'landing' && styles.Container__landing,
        (kind === 'cabinet' || kind === 'smallCabinet') && styles.Container__cabinet,
        className
      )}
    >
      <input
        className={styles.Input}
        required={isRequired}
        type="text"
        {...rest}
      />
      {withPostfix && postfix}
    </label>
  )
}
