import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { plural } from 'src/shared/lib/plural'
import { Tabs } from 'src/shared/ui/Tabs'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AdminAPI, CommonMessageResponse, HttpResponse, UserActivateDto, UserListResponseDto } from 'src/shared/api'
import { Flex } from 'src/shared/ui/Flex'
import { Blogger } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { RoleType, UserStatusType } from 'src/shared/config/types'
import { Customer } from 'src/shared/ui/Customer/Customer'
import { useNavigate } from 'react-router'
import { CABINET_USERS_ROUTE } from 'src/shared/config/consts'
import { RejectModal } from 'src/widgets/RejectModal'

import styles from './AdminCabinetUsers.module.scss'

enum TabType {
  VERIFICATION_REQUESTS = 'VERIFICATION_REQUESTS',
  BLOGGERS = 'BLOGGERS',
  CUSTOMERS = 'CUSTOMERS',
  BLOCKED_BLOGGERS = 'BLOCKED_BLOGGERS',
  BLOCKED_CUSTOMERS = 'BLOCKED_CUSTOMERS'
}

export const AdminCabinetUsers: FC = () => {
  const [activeTabId, setActiveTabId] = useState<string>(TabType.VERIFICATION_REQUESTS)
  const [usersCounts, setUsersCounts] = useState<Record<string, number>>({})
  const [bloggerToReject, setBloggerToReject] = useState<UserListResponseDto>()
  const queryClient = useQueryClient()

  const navigate = useNavigate()
  const openUser = useCallback((id: number) => navigate(`${CABINET_USERS_ROUTE}/${id}`), [navigate])

  const role = useMemo(() => {
    switch (activeTabId) {
      case TabType.CUSTOMERS:
      case TabType.BLOCKED_CUSTOMERS:
        return RoleType.CUSTOMER
      default:
        return RoleType.BLOGER
    }
  }, [activeTabId])

  const status = useMemo(() => {
    switch(activeTabId) {
      case TabType.VERIFICATION_REQUESTS:
        return UserStatusType.NOT_ACTIVATED
      case TabType.BLOGGERS:
      case TabType.CUSTOMERS:
        return UserStatusType.ACTIVATED
      default:
        return UserStatusType.CANCELED
    }
  }, [activeTabId])

  const isFullInfo = activeTabId === TabType.VERIFICATION_REQUESTS ? true : undefined

  const { data: usersData } = useQuery({
    queryKey: ['users', activeTabId],
    // TODO Добавить пагинацию
    queryFn: () => AdminAPI.api.managementControllerGetAllUsers({ page: 1, take: 200, role, status, isFullInfo }),
  })
  const users = (usersData?.data.data ?? []) as UserListResponseDto[]

  const { mutate: updateUserMutation, isPending: isLoading } = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    { userId: number, data: UserActivateDto }
  >({
    mutationFn: ({ userId, data }) => AdminAPI.api.managementControllerUserActivated(userId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['users'] })
  })

  useEffect(() => {
    if (usersData?.data) {
      const { data, ...newUsersCounts } = usersData.data

      if (newUsersCounts) {
        setUsersCounts(newUsersCounts)
      }
    }
  }, [usersData])

  const usersTotal = usersCounts.total

  const tabs = useMemo(() => [
    {
      tabId: TabType.VERIFICATION_REQUESTS,
      title: 'Заявки на верификацию',
      badge: usersCounts.numberToVerify
    },
    {
      tabId: TabType.BLOGGERS,
      title: 'Блогеры',
      badge: usersCounts.blogerCount
    },
    {
      tabId: TabType.CUSTOMERS,
      title: 'Селлеры',
      badge: usersCounts.customerCount,
    },
    {
      tabId: TabType.BLOCKED_BLOGGERS,
      title: 'Заблокированные блогеры',
      badge: usersCounts.blogerBlockedCount,
    },
    {
      tabId: TabType.BLOCKED_CUSTOMERS,
      title: 'Заблокированные селлеры',
      badge: usersCounts.customerBlockedCount,
    },
  ], [usersCounts])

  const renderUser = useCallback((user: UserListResponseDto) => {
    const userId = user.id
    const key = `${activeTabId}-${userId}`

    if (activeTabId === TabType.VERIFICATION_REQUESTS) {
      return (
        <Blogger
          key={key}
          {...user}
          onClick={() => openUser(userId)}
        >
          <Flex flexDirection="column" gap={8}>
            <CabinetActionButton
              kind="primary"
              disabled={isLoading}
              onClick={(e) => {
                e.stopPropagation()
                updateUserMutation({ userId, data: { status: UserStatusType.ACTIVATED } })
              }}
            >
              Подтвердить
            </CabinetActionButton>
            <CabinetActionButton
              kind="ghost"
              disabled={isLoading}
              onClick={(e) => {
                e.stopPropagation()
                setBloggerToReject(user)
              }}
            >
              Отклонить
            </CabinetActionButton>
          </Flex>
        </Blogger>
      )
    }

    if (activeTabId === TabType.BLOGGERS || activeTabId === TabType.CUSTOMERS) {
      const Component = activeTabId === TabType.BLOGGERS ? Blogger : Customer

      return (
        <Component
          key={key}
          {...user}
          onClick={() => openUser(userId)}
        >
          <CabinetActionButton
            kind="ghost"
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation()
              updateUserMutation({ userId, data: { status: UserStatusType.CANCELED } })
            }}
          >
            Заблокировать
          </CabinetActionButton>
        </Component>
      )
    }

    if (activeTabId === TabType.BLOCKED_BLOGGERS || activeTabId === TabType.BLOCKED_CUSTOMERS) {
      const Component = activeTabId === TabType.BLOCKED_BLOGGERS ? Blogger : Customer

      return (
        <Component
          key={key}
          {...user}
          onClick={() => openUser(userId)}
        >
          <CabinetActionButton
            kind="ghost"
            disabled={isLoading}
            onClick={(e) => {
              e.stopPropagation()
              updateUserMutation({ userId, data: { status: UserStatusType.ACTIVATED } })
            }}
          >
            Разблокировать
          </CabinetActionButton>
        </Component>
      )
    }

    return undefined
  }, [activeTabId, updateUserMutation, isLoading])

  const handleRejectModalSendForImprovement = useCallback(() => {
    updateUserMutation({ userId: bloggerToReject!.id, data: { status: UserStatusType.INCOMPLETE_DATA } })
    setBloggerToReject(undefined)
  }, [bloggerToReject])

  const handleRejectModalBlock = useCallback(() => {
    updateUserMutation({ userId: bloggerToReject!.id, data: { status: UserStatusType.CANCELED } })
    setBloggerToReject(undefined)
  }, [bloggerToReject])

  const handleRejectModalClose = useCallback(() => setBloggerToReject(undefined), [])

  return (
    <CabinetPageLayout>
      <h1 className={styles.Title}>
        {usersTotal && `${usersTotal} ${plural(['анкета', 'анкеты', 'анкет'], usersTotal)}`}
      </h1>
      <Tabs
        className={styles.Tabs}
        activeTabId={activeTabId}
        tabs={tabs}
        onTabClick={setActiveTabId}
      />
      <Flex className={styles.Users} flexWrap="wrap" gap={16}>
        {users.map(renderUser)}
      </Flex>
      {bloggerToReject && (
        <RejectModal
          blogger={bloggerToReject}
          onSendForImprovement={handleRejectModalSendForImprovement}
          onBlock={handleRejectModalBlock}
          onClose={handleRejectModalClose}
        />
      )}
    </CabinetPageLayout>
  )
}
