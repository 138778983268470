import React, { FC, useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { TextInputFormField } from 'src/shared/ui/TextInputFormField'
import { NumericInputFormField } from 'src/shared/ui/NumericInputFormField'
import { AdminAPI, BankDetailsDto, CommonMessageResponse, HttpResponse, UpsertBloggerDto } from 'src/shared/api'
import { TopicsMultiSelectFormField } from 'src/shared/ui/TopicsMultiSelectFormField'
import { CheckboxFormField } from 'src/shared/ui/CheckboxFormField'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import { CABINET_ROUTE } from 'src/shared/config/consts'
import { useUpload } from 'src/shared/model/useUpload'
import { UploaderField } from 'src/shared/ui/UploaderField'
import { BankDetailsFormField, DEFAULT_BANK_DETAILS } from 'src/shared/ui/BankDetailsFormField'

import styles from './BloggerVerification.module.scss'

interface BloggerVerificationFormFields {
  instagramLink: string
  city: string
  country: string
  followersCount: string
  coveragesCount: string
  topics: {topicId: string}[]
  bankDetails: Omit<BankDetailsDto, 'id'>[]
  isMessageSent: boolean
  isBotConnected: boolean
}

export const BloggerVerification: FC = () => {
  const navigate = useNavigate()

  const [videoStatisticsFile, setVideoStatisticsFile] = useState<File>()
  const [genderStatisticsFile, setGenderStatisticsFile] = useState<File>()
  const [countriesStatisticsFile, setCountriesStatisticsFile] = useState<File>()
  const [lastStoryStatisticsFile, setLastStoryStatisticsFile] = useState<File>()
  const [publicationStatisticsFile, setPublicationStatisticsFile] = useState<File>()

  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const methods = useForm<BloggerVerificationFormFields>({
    defaultValues: {
      bankDetails: [
        DEFAULT_BANK_DETAILS
      ],
      topics: [
        {
          topicId: undefined
        }
      ]
    }
  })
  const { handleSubmit, watch, setError } = methods
  const values = watch()

  const updateBloggerMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpsertBloggerDto
  >({
    mutationFn: AdminAPI.api.bloggerControllerUpdate,
  })
  const isLoading = updateBloggerMutation.isPending || isUploading

  const onSubmit = useCallback(async (data: BloggerVerificationFormFields) => {
    try {
      setIsUploading(true)
      const videoStatistics = await upload(videoStatisticsFile!)
      const genderStatistics = await upload(genderStatisticsFile!)
      const countriesStatistics = await upload(countriesStatisticsFile!)
      const lastStoryStatistics = await upload(lastStoryStatisticsFile!)
      const publicationStatistics = await upload(publicationStatisticsFile!)
      setIsUploading(false)

      const upsertBloggerDto: UpsertBloggerDto = {
        instagramLink: data.instagramLink,
        city: data.city,
        country: data.country,
        followersCount: Number(data.followersCount),
        coveragesCount: Number(data.coveragesCount),
        blogTopics: {
          create: data.topics.map(({ topicId }) => ({
            topicId: Number(topicId)
          })),
          delete: []
        },
        bankDetails: data.bankDetails.map((item) => ({
          ...item,
          accountPhone: `+7${item.accountPhone}`
        })) as BankDetailsDto[],
        verifyInfo: {
          videoStatistics,
          genderStatistics,
          countriesStatistics,
          lastStoryStatistics,
          publicationStatistics,
        }
      }

      updateBloggerMutation.mutate(upsertBloggerDto, {
        onSuccess: () => {
          navigate(CABINET_ROUTE, { replace: true })
        },
        onError: (error) => {
          if (error.error) {
            setError('instagramLink', error.error)
          }
        }
      })
    } catch (e) {
      console.error(e)
      setIsUploading(false)
    }
  }, [upload, videoStatisticsFile, genderStatisticsFile, countriesStatisticsFile, lastStoryStatisticsFile, publicationStatisticsFile])

  const isTopicsValid = useMemo(() => values.topics.every((item) => item.topicId !== undefined), [values])

  const isBankDetailsValid = useMemo(() => values.bankDetails.every((item) =>
    item.accountNumber &&
    item.accountPhone &&
    item.bankName &&
    item.names
  ), [values])

  const isButtonDisabled =
    !isTopicsValid ||
    !isBankDetailsValid ||
    !videoStatisticsFile ||
    !genderStatisticsFile ||
    !countriesStatisticsFile ||
    !lastStoryStatisticsFile ||
    !values.instagramLink ||
    !values.followersCount ||
    !values.country ||
    !values.city ||
    !values.isMessageSent ||
    !values.isBotConnected ||
    isLoading

  return (
    <FormProvider {...methods}>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <Flex className={styles.InputsContainer} flexDirection="column">
          <TextInputFormField
            id="instagramLink"
            name="instagramLink"
            label="Ссылка на Instagram"
            placeholder="Ссылка"
            kind="cabinet"
            disabled={isLoading}
          />
          <NumericInputFormField
            id="followersCount"
            name="followersCount"
            label="Количество подписчиков"
            placeholder="Укажите количество ваших подписчиков"
            kind="cabinet"
            disabled={isLoading}
          />
          <NumericInputFormField
            id="coveragesCount"
            name="coveragesCount"
            label="Количество охватов"
            placeholder="Укажите количество ваших охватов"
            kind="cabinet"
            disabled={isLoading}
          />
          <Flex flexDirection="column" gap={16}>
            <Flex flexDirection="column" gap={8}>
              <span className={styles.Label}>Охваты</span>
              <span className={styles.Description}>
                Прикрепите скриншоты статистики из 3 последних сторис и видеозапись раздела охватов
              </span>
            </Flex>
            <UploaderField
              label="1. Загрузите видеозапись экрана вашей общей статистики просмотров за 7,14,30 дней. Видеозапись должна быть не более 1 минуты:"
              id="video"
              kind="smallCabinet"
              type="video"
              file={videoStatisticsFile}
              onChange={setVideoStatisticsFile}
            />
            <UploaderField
              label="2. Загрузите скриншот статистики возраста аудитории и пола:"
              id="image1"
              kind="smallCabinet"
              type="image"
              placeholder="Загрузить скриншот"
              file={genderStatisticsFile}
              onChange={setGenderStatisticsFile}
            />
            <UploaderField
              label="3. Загрузите скриншот статистики городов аудитории:"
              id="image2"
              kind="smallCabinet"
              type="image"
              placeholder="Загрузить скриншот"
              file={countriesStatisticsFile}
              onChange={setCountriesStatisticsFile}
            />
            <UploaderField
              label="4. Загрузите скриншот статистики последней истории, где прошло 24 ч и более:"
              id="image3"
              kind="smallCabinet"
              type="image"
              placeholder="Загрузить скриншот"
              file={lastStoryStatisticsFile}
              onChange={setLastStoryStatisticsFile}
            />
            <UploaderField
              label="5. Загрузите скриншот статистики последнего поста, где прошло 24ч и более:"
              id="image4"
              kind="smallCabinet"
              type="image"
              placeholder="Загрузить скриншот"
              file={publicationStatisticsFile}
              onChange={setPublicationStatisticsFile}
            />
          </Flex>
          <TextInputFormField
            id="country"
            name="country"
            label="Страна"
            placeholder="Страна"
            kind="cabinet"
            disabled={isLoading}
          />
          <TextInputFormField
            id="city"
            name="city"
            label="Город"
            placeholder="Город"
            kind="cabinet"
            disabled={isLoading}
          />
          <TopicsMultiSelectFormField
            id="topics"
            name="topics"
            fieldName="topicId"
            label="Тематика блога"
            placeholder="Выберите тематику из списка"
            addButtonText="Добавить тематику"
            disabled={isLoading}
          />
          <BankDetailsFormField
            id="bankDetails"
            name="bankDetails"
            label="Банковские реквизиты"
            disabled={isLoading}
          />
        </Flex>
        <Flex className={styles.InputsContainer} flexDirection="column">
          <Flex flexDirection="column" gap={16}>
            <span className={styles.Label}>Подтверждение в Instagram</span>
            <span className={styles.Text}>Напишите нам в Instagram <a className={styles.Link} href="https://www.instagram.com/marketiq.pr" target="_blank" rel="noreferrer">@marketiq</a> и отправьте символы: %#$@</span>
          </Flex>
          <CheckboxFormField
            id="isMessageSent"
            name="isMessageSent"
            withHiddenLabel
          >
            Я отправил сообщение в Instagram
          </CheckboxFormField>
        </Flex>
        <Flex className={styles.InputsContainer} flexDirection="column">
          <Flex flexDirection="column" gap={16}>
            <span className={styles.Label}>Подключите нашего Telegram-бота</span>
            <a className={styles.Link} href="t.me/Marketiq_app_bot" target="_blank" rel="noreferrer">Ссылка на бот</a>
          </Flex>
          <CheckboxFormField
            id="isBotConnected"
            name="isBotConnected"
            withHiddenLabel
          >
            Я подключил бота
          </CheckboxFormField>
        </Flex>
        <CabinetButton className={styles.CabinetButton} disabled={isButtonDisabled}>
          Отправить на верификацию
        </CabinetButton>
      </form>
    </FormProvider>
  )
}
