import React, { FC } from 'react'
import { Main } from 'src/widgets/Main'
import { LandingPage } from 'src/widgets/LandingPage'
import { AnyQuestions } from 'src/widgets/AnyQuestions'

import { CUSTOMER_LANDING_ROUTE } from 'src/shared/config/consts'

import mainBackgroundGradient1 from 'src/shared/assets/main_background_gradient_1.svg'
import mainBackgroundGradient3 from 'src/shared/assets/main_background_gradient_3.svg'
import theyTalkAboutUsBackgroundGradient from 'src/shared/assets/they_talk_about_background_gradient.svg'
import bottomBackgroundGradient from 'src/shared/assets/bottom_background_gradient.svg'

import { MainImages } from '../MainImages/MainImages'
import { Products } from '../Products/Products'
import { Stages } from '../Stages/Stages'
import { BloggersCount } from '../BloggersCount/BloggersCount'
import { TheyTalkAboutUs } from '../TheyTalkAboutUs/TheyTalkAboutUs'

import styles from './BloggerLanding.module.scss'

export const BloggerLanding: FC = () => {
  return (
    <LandingPage arrowButtonText="Селерам" arrowButtonLink={CUSTOMER_LANDING_ROUTE}>
      <div className={styles.Background}>
        <img className={styles.MainBackgroundGradient1} src={mainBackgroundGradient1} alt="" aria-hidden="true" />
        <img className={styles.MainBackgroundGradient3} src={mainBackgroundGradient3} alt="" aria-hidden="true" />
        <img
          className={styles.TheyTalkAboutUsBackgroundGradient}
          src={theyTalkAboutUsBackgroundGradient}
          alt=""
          aria-hidden="true"
        />
        <img className={styles.BottomBackgroundGradient} src={bottomBackgroundGradient} alt="" aria-hidden="true" />
      </div>
      <Main
        title={
          <>
            Приобретайте сотни товаров за рекламу <span className="alphaText">у себя в блоге</span>
          </>
        }
        descriptionTitle="Как мы работаем:"
        descriptionText="Вы получаете от селеров товары по бартеру в обмен на рекламу в вашем блоге."
        type="blogger"
      >
        <MainImages />
      </Main>
      <Products className={styles.Products} />
      <Stages className={styles.Stages} />
      <BloggersCount className={styles.BloggersCount} />
      <TheyTalkAboutUs className={styles.TheyTalkAboutUs} />
      <AnyQuestions className={styles.AnyQuestions} />
    </LandingPage>
  )
}
