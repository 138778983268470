import React, { useMemo, type FC } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { useRootRoutes } from '../../model/useRootRoutes'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LOGIN_ROUTE } from 'src/shared/config/consts'

import './App.scss'

const handleError = (error: Error) => {
  // @ts-expect-error
  if (error.status === 401) {
    window.location.replace(LOGIN_ROUTE)
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    }
  },
  mutationCache: new MutationCache({
    onError: handleError,
  }),
  queryCache: new QueryCache({
    onError: handleError,
  }),
})

export const App: FC = () => {
  const routes = useRootRoutes()

  const router = useMemo(() => createBrowserRouter(routes), [routes])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}

