import React, { FC } from 'react'
import Logo from 'src/shared/assets/logo.svg'

import styles from './Footer.module.scss'

export const Footer: FC = () => {
  return (
    <footer className={styles.Footer}>
      <div className={styles.Container}>
        <img className={styles.Logo} src={Logo} alt="Логотип" />
        <a className={styles.Link} href="#" target="_blank" rel="noreferrer">
          Договор
        </a>
      </div>
    </footer>
  )
}
