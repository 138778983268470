import React, { FC, ReactNode } from 'react'
import { Avatar } from 'src/shared/ui/Avatar'
import { Flex } from '../Flex'
import cn from 'classnames'

import styles from './Customer.module.scss'

const numberFormat = new Intl.NumberFormat('ru')

export interface CustomerPropsType {
  firstName: string
  avatar?: string
  products?: number
  orders?: number
  children?: ReactNode
  onClick?(): void
}

export const Customer: FC<CustomerPropsType> = ({ firstName, avatar, products, orders, children, onClick }) => {
  return (
    <Flex className={cn(styles.Customer, onClick && styles.withPointerCursor)} flexDirection="column" gap={20} onClick={onClick}>
      <Flex className={styles.Header} alignItems="center" gap={16}>
        <Avatar className={styles.Image} avatar={avatar} firstName={firstName} />
        <span className={styles.Name}>{firstName}</span>
      </Flex>
      <Flex flexDirection="column" gap={12}>
        <Flex gap={8}>
          <span className={styles.Title}>Товаров:</span>
          <span className={styles.Value}>{products !== undefined ? numberFormat.format(products) : '-'}</span>
        </Flex>
        <Flex gap={8}>
          <span className={styles.Title}>Заказов:</span>
          <span className={styles.Value}>{orders !== undefined ? numberFormat.format(orders) : '-'}</span>
        </Flex>
      </Flex>
      {children}
    </Flex>
  )
}
