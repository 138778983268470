import React, { FC } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'

import { CabinetFAQ } from '../CabinetFAQ/CabinetFAQ'
import { CabinetLinks } from '../CabinetLinks/CabinetLinks'

import styles from './Cabinet.module.scss'

export const Cabinet: FC = () => {
  return (
    <CabinetPageLayout>
      <div className={styles.Layout}>
        <CabinetFAQ />
        <CabinetLinks />
      </div>
    </CabinetPageLayout>
  )
}
