import React, { FC, useState } from 'react'
import cn from 'classnames'
import previewImage from 'src/shared/assets/video_preview.webp'

import styles from './Video.module.scss'

interface VideoPropsType {
  className: string
}

export const Video: FC<VideoPropsType> = ({ className }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const handlePlayVideo = () => {
    setIsPlaying(true)
  }

  const handleIframeLoad = () => {
    setIsLoaded(true)
  }

  return (
    <section className={cn(styles.Video, isLoaded && styles.Active, className)} aria-label="Видео о сервисе">
      <div className={styles.PreviewImage} onClick={handlePlayVideo}>
        <img src={previewImage} alt="Превью видео" />
        <button className={styles.Btn} aria-label="Начать видео"></button>
      </div>
      {isPlaying && (
        <iframe
          className={styles.Iframe}
          src="https://vk.com/video_ext.php?oid=-29218811&id=456246336&t=0&hd=2&autoplay=1"
          allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
          frameBorder="0"
          allowFullScreen
          onLoad={handleIframeLoad}
        ></iframe>
      )}
    </section>
  )
}
