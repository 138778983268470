import { ControllerRenderProps, FieldValues, UseControllerProps } from "react-hook-form"
import { OrderListResponseDto, OrderByCustomerResponseDto as GeneratedOrderByCustomerResponseDto, OrderResponsesResponseDto as GeneratedOrderResponsesResponseDto, BlogerOrderResponseDto } from "../api"

export interface PageConfigType {
  title: string
  route: string
}

export type PrepareFieldPropsType<TFieldProps> = Omit<
  TFieldProps,
  'error' | 'defaultValue' | keyof Omit<ControllerRenderProps, 'onBlur'>
>

export type ReactHookFormFieldType<TFieldValues extends FieldValues, TFieldProps extends Record<string, any>> = Omit<
  UseControllerProps<TFieldValues>,
  'control'
> &
  PrepareFieldPropsType<TFieldProps>

export enum CollectionType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

export enum RoleType {
  CUSTOMER = 'CUSTOMER',
  BLOGER = 'BLOGER',
  SUPER_ADMIN = 'SUPER_ADMIN'
}

export enum OrderStatusType {
  COLLECTING = 'COLLECTING',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSE = 'PAUSE',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED'
}

export enum BloggerStatusType {
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  ACTIVATED = 'ACTIVATED'
}

export enum CustomReporterType {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  WAIT_PAYMENT = 'WAIT_PAYMENT',
  WAIT_PAY_CONFIRM = 'WAIT_PAY_CONFIRM',
  PAID = 'PAID',
  COMPLETED_STEP_2 = 'COMPLETED_STEP_2',
  COMPLETED = 'COMPLETED'
}

export enum ProductStatusType {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED'
}

export enum UserStatusType {
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  ACTIVATED = 'ACTIVATED',
  CANCELED = 'CANCELED',
  INCOMPLETE_DATA = 'INCOMPLETE_DATA'
}

export interface CustomerOrderListResponseDto extends OrderListResponseDto {
  collection?: string
  marketplaceName?: string
  maxParticipants?: number
  status?: string
  customReportersStatistic?: Record<string, number>
}

export interface BloggerOrderListResponseDto extends OrderListResponseDto {
  collection?: string
  currernCountParticipants?: number
  maxParticipants?: number
  reportStatus?: string
  status?: string
}

export interface OrderByCustomerResponseDto extends Omit<GeneratedOrderByCustomerResponseDto, 'customReporters' | 'blogers'> {
  customReportersStatistic?: Record<string, number>
  blogers?: BlogerOrderResponseDto[]
}

export type BloggerType = any

export interface OrderResponsesResponseDto extends GeneratedOrderResponsesResponseDto {
  bloger: BloggerType
  orderVideo: string
  orderScreenshot: string
  screenshotMyPurchases: string
  screenshotShtrihcode: string
  reviewsScreenshot: string
  realsLink: string
  storiesScreenshot: string
  statisticsScreenshot: string
}
