import React, { FC, useMemo } from 'react'
import { CustomReporterType, OrderResponsesResponseDto } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'
import { Media } from 'src/shared/ui/Media'
import { Field } from 'src/shared/ui/Field'

import styles from './MediaSections.module.scss'

interface MediaSectionsPropsType {
  orderResponse: OrderResponsesResponseDto
}

export const MediaSections: FC<MediaSectionsPropsType> = ({ orderResponse }) => {
  const isSecondSectionVisible = useMemo(() => [
    CustomReporterType.PAID,
    CustomReporterType.COMPLETED_STEP_2,
    CustomReporterType.COMPLETED,
  ].includes(orderResponse.status as CustomReporterType), [])

  const isThirdSectionVisible = useMemo(() => orderResponse.status === CustomReporterType.COMPLETED, [])

  return (
    <Flex flexDirection="column" gap={32}>
      <Flex flexDirection="column" gap={24}>
        <h1 className={styles.Title}>1 раздел. Заказ</h1>
        <div className={styles.List}>
          <Media
            type="video"
            src={orderResponse.orderVideo}
            desciption="Видео заказа"
          />
          <Media
            type="image"
            src={orderResponse.orderScreenshot}
            desciption="Скриншот заказа"
          />
        </div>
      </Flex>
      {isSecondSectionVisible && (
        <Flex flexDirection="column" gap={24}>
          <h1 className={styles.Title}>2 раздел. Выкуп</h1>
          <div className={styles.List}>
            <Media
              type="image"
              src={orderResponse.screenshotMyPurchases}
              desciption="Скрин раздела покупок"
            />
            <Media
              type="image"
              src={orderResponse.screenshotShtrihcode}
              desciption="Фото разрезанного штрихкода"
            />
          </div>
        </Flex>
      )}
      {isThirdSectionVisible && (
        <Flex flexDirection="column" gap={24}>
          <h1 className={styles.Title}>3 раздел. Реклама</h1>
          <Field
            id="realsLink"
            label="Ссылка на рилс"
            kind="cabinet">
            <a
              className={styles.Link}
              href={orderResponse.realsLink}
              target="_blank"
              rel="noreferrer"
            >
              {orderResponse.realsLink}
            </a>
          </Field>
          <div className={styles.List}>
            <Media
              type="image"
              src={orderResponse.reviewsScreenshot}
              desciption="Скрин отзыва"
            />
            <Media
              type="image"
              src={orderResponse.storiesScreenshot}
              desciption="Скрин сторис"
            />
            <Media
              type="image"
              src={orderResponse.statisticsScreenshot}
              desciption="Скрин статистики на сторис"
            />
          </div>
        </Flex>
      )}
    </Flex>
  )
}
