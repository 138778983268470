import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { FC, useCallback, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { AdminAPI, CommonMessageResponse, HttpResponse, ProductResponseDto, UpdateProductDto } from 'src/shared/api'
import { CABINET_PRODUCTS_ROUTE } from 'src/shared/config/consts'
import { useUpload } from 'src/shared/model/useUpload'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { ProductForm as BaseProductForm, ProductFormFields, useProductForm } from 'src/widgets/ProductForm'

import styles from './ProductForm.module.scss'

interface ProductFormPropsType {
  productId: number
  product: ProductResponseDto
}

export const ProductForm: FC<ProductFormPropsType> = ({ productId, product }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [isUploading, setIsUploading] = useState(false)
  const upload = useUpload()

  const { methods, isInputsInvalid } = useProductForm(product)
  const { handleSubmit } = methods

  const updateProductMutation = useMutation<
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    HttpResponse<CommonMessageResponse, void | CommonMessageResponse>,
    UpdateProductDto
  >({
    mutationFn: (data: UpdateProductDto) => AdminAPI.api.productsControllerUpdate(productId, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['products', productId] })
  })
  const isLoading = updateProductMutation.isPending || isUploading

  const onSubmit = useCallback(async (data: ProductFormFields) => {
    try {
      let photoUrl: string
      if (typeof data.photo === 'string') {
        photoUrl = data.photo
      } else {
        setIsUploading(true)
        photoUrl = await upload(data.photo)
        setIsUploading(false)
      }

      const updateProductDto: UpdateProductDto = {
        topics: data.topics.filter(item => item.topicId).map(item => ({ topicId: Number(item.topicId) })),
        marketplaceUrl: data.marketplaceUrl,
        marketplaceId: Number(data.marketplaceId),
        title: data.title,
        description: data.description,
        article: data.article,
        price: Number(data.price),
        photoUrl,
      }

      updateProductMutation.mutate(updateProductDto, {
        onSuccess: () => {
          navigate(CABINET_PRODUCTS_ROUTE, { replace: true })
        },
        onError: (error) => {
          console.error(error.error)
        }
      })
    } catch (e) {
      console.error(e)
      setIsUploading(false)
    }
  }, [updateProductMutation, product, navigate])

  return (
    <FormProvider {...methods}>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <BaseProductForm isDisabled={isLoading} />
        <CabinetButton className={styles.CabinetButton} disabled={isInputsInvalid}>
          Сохранить товар
        </CabinetButton>
      </form>
    </FormProvider>
  )

}
