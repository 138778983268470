import React, { FC, useCallback } from 'react'
import { Flex } from 'src/shared/ui/Flex'
import { BloggerOrderListResponseDto, CustomReporterType, RoleType } from 'src/shared/config/types'
import { Collection } from 'src/shared/ui/Collection'
import { OrderStatus } from 'src/shared/ui/OrderStatus'

import styles from './Task.module.scss'

interface TaskPropsType {
  task: BloggerOrderListResponseDto
  onClick(): void
}

export const Task: FC<TaskPropsType> = ({ task, onClick }) => {
  const { photoUrl, title, collection, status, currernCountParticipants, maxParticipants, reportStatus } = task

  const renderStage = useCallback(() => {
    if (reportStatus === CustomReporterType.REJECTED) {
      return null
    }

    let stageName: string

    if (reportStatus === CustomReporterType.PENDING) {
      stageName = 'Ожидание подтверждения участия'
    } else if (reportStatus === CustomReporterType.CONFIRMED) {
      stageName = 'Принят в ручном заказе'
    } else if (reportStatus === CustomReporterType.WAIT_PAYMENT) {
      stageName = 'Ожидание оплаты от селлера'
    } else if (reportStatus === CustomReporterType.WAIT_PAY_CONFIRM) {
      stageName = 'Ожидание подтверждения оплаты от блогера'
    } else if (reportStatus === CustomReporterType.PAID ) {
      stageName = 'Блогер подтвердил оплату'
    } else if (reportStatus === CustomReporterType.COMPLETED_STEP_2 ) {
      stageName = 'Блогер заполнил 2 этап и может приступать к 3'
    } else {
      stageName = '3 этап полностью заполнен'
    }

    return (
      <Flex className={styles.Footer} gap={8} flexDirection="column">
        <span className={styles.Stage}>Этап:</span>
        <span className={styles.CurrentStage}>{stageName}</span>
      </Flex>
    )
  }, [reportStatus])

  return (
    <Flex className={styles.Task} flexDirection="column" onClick={onClick}>
      <Flex className={styles.Header}>
        <Flex className={styles.Main} alignItems="center">
          <img className={styles.Photo} src={photoUrl} />
          <Flex flexDirection="column" gap={8}>
            <span className={styles.Title}>{title}</span>
            <Collection collection={collection!} />
          </Flex>
        </Flex>
        <OrderStatus
          status={status!}
          collection={collection!}
          confirmedCount={currernCountParticipants!}
          maxParticipants={maxParticipants!}
          role={RoleType.BLOGER}
        />
      </Flex>
      {renderStage()}
    </Flex>
  )
}
