import React, { FC } from 'react'
import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { ProgressBar, ProgressBarPropsType, StagesEnum } from 'src/shared/ui/ProgressBar/ProgressBar'
import { Flex } from 'src/shared/ui/Flex'

import styles from './ProgressBlogger.module.scss'

interface ProgressBloggerPropsType extends BloggerPropsType, ProgressBarPropsType {
  onIntegrationDetailClick(): void
}

export const ProgressBlogger: FC<ProgressBloggerPropsType> = ({ stage, onIntegrationDetailClick, ...props }) => {
  return (
    <Blogger
      {...props}
    >
      <Flex className={styles.Container} flexDirection="column" alignItems="center">
        <ProgressBar stage={stage} />
        <span className={styles.Text}>
          {stage === StagesEnum.STAGE_1 ? 'Делает выкуп' : 'Делает рекламу'}
        </span>
        <CabinetActionButton className={styles.Button} onClick={onIntegrationDetailClick}>Детали интеграции</CabinetActionButton>
      </Flex>
    </Blogger>
  )
}
