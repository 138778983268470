import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router'
import { UserResponseDto } from 'src/shared/api'
import { CABINET_TASKS_ROUTE } from 'src/shared/config/consts'
import { CustomerOrderListResponseDto, RoleType } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'
import { Task } from 'src/shared/ui/Task'

interface TasksPropsType {
  user: UserResponseDto
}

export const Tasks: FC<TasksPropsType> = ({ user }) => {
  const navigate = useNavigate()
  const openTask = useCallback((id: number) => navigate(`${CABINET_TASKS_ROUTE}/${id}`), [navigate])
  // @ts-ignore
  const orders = ((user.role === RoleType.CUSTOMER ? user.customer?.orders : user.bloger?.orders) ?? []) as unknown as CustomerOrderListResponseDto[]

  return (
    <Flex flexDirection="column" gap={20}>
      {orders.map((item) => (
        <Task
          key={item.id}
          task={item}
          onClick={openTask}
        />
      ))}
    </Flex>
  )
}
