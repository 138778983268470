import React, { FC, FormEvent, useCallback, useState } from 'react'
import { AdminAPI, UserResponseDto } from 'src/shared/api'
import { NumericInputField } from 'src/shared/ui/NumericInputField'
import { CabinetButton } from 'src/shared/ui/CabinetButton'
import { Flex } from 'src/shared/ui/Flex'
import { NumericInputOnChangeHandlerType } from 'src/shared/ui/NumericInput/NumericInput'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import styles from './Balance.module.scss'

interface BalancePropsType {
  user: UserResponseDto
}

export const Balance: FC<BalancePropsType> = ({ user }) => {
  const queryClient = useQueryClient()

  const customerId = user.customer!.id!
  const previousBalance = user.customer!.total || 1
  const [balance, setBlance] = useState(previousBalance)
  const [errorMessage, setErrorMessage] = useState<string>()

  const { mutateAsync: setCustomerBalanceMutation, isPending: isLoading } = useMutation({
    mutationFn: AdminAPI.api.managementControllerSetCustomerBalance,
    onSuccess: () => {
      setErrorMessage(undefined)
      queryClient.invalidateQueries({ queryKey: ['users', user.id] })
    },
    // @ts-ignore
    onError: (error: Error) => setErrorMessage(error?.error?.message)
  })

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (balance <= 0) {
      setErrorMessage('Баланс должен быть положительным числом')
      return
    }

    try {
      await setCustomerBalanceMutation({
        customerId,
        previousBalance,
        total: balance
      })
    } catch(error) {
      console.error(error);
    }
  }, [setCustomerBalanceMutation, customerId, previousBalance, balance])

  const handleBalanceChange = useCallback<NumericInputOnChangeHandlerType>((_, value) => setBlance(Number(value)), [])

  return (
    <form onSubmit={handleSubmit}>
      <Flex className={styles.Balance} flexDirection="column" gap={24}>
        <div className={styles.InputsContainer}>
          <NumericInputField
            id="total"
            kind="cabinet"
            label="Баланс (в интеграциях)"
            value={balance}
            onChange={handleBalanceChange}
            error={errorMessage}
          />
        </div>
        <CabinetButton
          className={styles.CabinetButton}
          disabled={isLoading}
        >
          Сохранить
        </CabinetButton>
      </Flex>
    </form>
  )
}
