import React, { ChangeEventHandler, FC, FormEvent, useCallback, useState } from 'react'
import { CabinetPageLayout } from 'src/widgets/CabinetPageLayout'
import { Flex } from 'src/shared/ui/Flex'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AdminAPI } from 'src/shared/api'
import { Input } from 'src/shared/ui/Input'
import { Icon } from 'src/shared/ui/Icon'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'

import styles from './AdminCabinetTopics.module.scss'
import { useTopics } from 'src/shared/model/useTopics'

export const AdminCabinetTopics: FC = () => {
  const [isAddNewTopic, setIsAddNewTopic] = useState(false)
  const [newTopicName, setNewTopicName] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>()

  const queryClient = useQueryClient()

  const topics = useTopics()

  // @ts-ignore
  const onError = useCallback((error: Error) => setErrorMessage(error?.error?.message), [])

  const { mutate: addTopicMutation, isPending: isAddTopicLoading } = useMutation({
    mutationFn: AdminAPI.api.managementControllerAddTopic,
    onSuccess: () => {
      setIsAddNewTopic(false)
      setNewTopicName('')
      setErrorMessage(undefined)
      queryClient.invalidateQueries({ queryKey: ['topics'] })
    },
    onError
  })

  const { mutate: removeTopicMutation } = useMutation({
    mutationFn: AdminAPI.api.managementControllerRemoveTopic,
    onSuccess: () => {
      setErrorMessage(undefined)
      queryClient.invalidateQueries({ queryKey: ['topics'] })
    },
    onError
  })

  const addTopic = useCallback(() => {
    if (!newTopicName) {
      return
    }

    addTopicMutation({ name: newTopicName })
  }, [newTopicName, addTopicMutation])

  const handleNewTopicNameInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewTopicName(event.target.value)
    setErrorMessage(undefined)
  }, [])

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    addTopic()
  }, [addTopic])

  const handleAddButtonClick = useCallback(() => setIsAddNewTopic(true), [])

  const handleCancelButtonClick = useCallback(() => {
    setIsAddNewTopic(false)
    setNewTopicName('')
  }, [])

  return (
    <CabinetPageLayout className={styles.CabinetPageLayout}>
      <h1 className={styles.Title}>Топики товаров</h1>
      <Flex className={styles.Topics} flexDirection="column" gap={8}>
        {topics.map(item => (
          <Flex key={item.name} alignItems="center" gap={20}>
            <Input
              kind="cabinet"
              value={item.name}
              readOnly
            />
            <Icon
              className={styles.CloseIcon}
              name="close"
              onClick={() => removeTopicMutation(item.id)}
            />
          </Flex>
        ))}
        {isAddNewTopic ? (
          <form onSubmit={handleSubmit}>
            <Flex alignItems="center" gap={20}>
              <Input
                kind="cabinet"
                value={newTopicName}
                onChange={handleNewTopicNameInputChange}
                disabled={isAddTopicLoading}
                autoFocus
              />
              <Icon
                className={styles.CloseIcon}
                name="close"
                onClick={handleCancelButtonClick}
              />
            </Flex>
          </form>
        ) : (
          <CabinetActionButton
            className={styles.AddButton}
            kind="ghost"
            onClick={handleAddButtonClick}
          >
            Добавить топик
          </CabinetActionButton>
        )}
        {errorMessage && <span className={styles.Error}>{errorMessage}</span>}
      </Flex>
    </CabinetPageLayout>
  )
}
