import React, { FC } from 'react'

import { Field, FieldPropsType } from '../Field'
import { Uploader, UploaderPropsType } from '../Uploader/Uploader'

export type UploaderFieldPropsType = Omit<FieldPropsType, 'children'> & UploaderPropsType

export const UploaderField: FC<UploaderFieldPropsType> = ({ className, kind, label, id, error, isRequired, ...rest }) => {
  return (
    <Field className={className} kind={kind} label={label} id={id} error={error} isRequired={isRequired}>
      <Uploader {...rest} />
    </Field>
  )
}
