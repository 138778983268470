import React, { FC, useCallback } from 'react'
import { Flex } from 'src/shared/ui/Flex'
import cn from 'classnames'

import styles from './Media.module.scss'

interface MediaPropsType {
  className?: string
  src?: string
  desciption: string
  type: 'image' | 'video'
}

export const Media: FC<MediaPropsType> = ({ className, src, desciption, type }) => {
  const renderContent = useCallback(() => {
    if (!src) {
      return null
    }

    return type === 'image' ? (
      <img className={styles.Image} src={src} alt={desciption} />
    ) : (
      <video className={styles.Video} src={src} controls />
    )
  }, [src, desciption, type])

  return (
    <Flex className={cn(styles.Media, className)} flexDirection="column" alignItems="center" gap={12}>
      <div className={styles.Preview}>
        {renderContent()}
      </div>
      <span className={styles.Description}>
        {desciption}
      </span>
    </Flex>
  )
}
