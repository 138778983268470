import React, { FC } from 'react'
import { CABINET_ROUTE } from 'src/shared/config/consts'

import styles from './Logo.module.scss'
import { Link } from 'react-router-dom'

export const Logo: FC = () => {
  return (
    <Link to={CABINET_ROUTE} className={styles.Logo}>
      Marketiq
    </Link>
  )
}
