import { useMemo } from "react"
import { DeepPartial, useForm } from "react-hook-form"
import { ProductResponseDto } from "src/shared/api"
import { ProductFormFields } from "../config/types"

export const useProductForm = (product?: ProductResponseDto) => {
  const defaultValues = useMemo<DeepPartial<ProductFormFields>>(() => {
    if (product) {
      return {
        topics: (product.topics as any[]).map(item => ({ topicId: String(item.id) })),
        article: product.article,
        description: product.description,
        marketplaceId: String(product.marketplaceId),
        marketplaceUrl: product.marketplaceUrl,
        price: String(product.price),
        title: product.title,
        photo: product.photoUrl
      }
    }

    return {
      topics: [
        {
          topicId: undefined
        }
      ]
    }
  }, [product])

  const methods = useForm<ProductFormFields>({
    defaultValues,
  })

  const { watch } = methods
  const values = watch()

  const isInputsInvalid = useMemo(() =>
    values.topics?.every(item => item.topicId === undefined) ||
    !values.marketplaceUrl ||
    !values.marketplaceId ||
    !values.title ||
    !values.description ||
    !values.article ||
    !values.price ||
    !values.photo,
  [values])

  return { methods, isInputsInvalid }
}
