import React, { ReactElement } from 'react'
import { TextInputField, type TextInputFieldPropsType } from '../TextInputField'
import type { FieldValues } from 'react-hook-form'
import { useReactHookFormFieldController } from 'src/shared/model/useReactHookFormFieldController'
import { ReactHookFormFieldType } from 'src/shared/config/types'

type TextInputFormFieldPropsType<TFieldValues extends FieldValues = FieldValues> = ReactHookFormFieldType<TFieldValues, TextInputFieldPropsType>

export const TextInputFormField = <TFieldValues extends FieldValues = FieldValues>(
  {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    ...props
  }: TextInputFormFieldPropsType<TFieldValues>
): ReactElement<TextInputFormFieldPropsType<TFieldValues>> => {
  const {
    field,
    error,
  } = useReactHookFormFieldController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return (
    <TextInputField
      {...field}
      error={error}
      {...props}
    />
  )
}
