import React, { FC } from 'react'
import { Blogger, BloggerPropsType } from 'src/shared/ui/Blogger/Blogger'
import { CabinetActionButton } from 'src/shared/ui/CabinetActionButton'
import { RoleType } from 'src/shared/config/types'
import { Flex } from 'src/shared/ui/Flex'

interface RespondedBloggerPropsType extends BloggerPropsType {
  role: RoleType
  onConfirmClick?(): void
  onRejectClick?(): void
}

export const RespondedBlogger: FC<RespondedBloggerPropsType> = ({ role, onConfirmClick, onRejectClick, ...props }) => {
  return (
    <Blogger
      {...props}
    >
      {role === RoleType.CUSTOMER && (
        <Flex flexDirection="column" alignItems="center" gap={8}>
          <CabinetActionButton onClick={onConfirmClick}>Подтвердить участника</CabinetActionButton>
          <CabinetActionButton kind="ghost" onClick={onRejectClick}>Отклонить участника</CabinetActionButton>
        </Flex>
      )}
    </Blogger>
  )
}
