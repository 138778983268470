import React, { ReactElement } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useReactHookFormFieldController } from 'src/shared/model/useReactHookFormFieldController'
import { PhoneInputField, PhoneInputFieldPropsType } from '../PhoneInputField'
import { ReactHookFormFieldType } from 'src/shared/config/types'

type PhoneInputFormFieldPropsType<TFieldValues extends FieldValues = FieldValues> = ReactHookFormFieldType<TFieldValues, PhoneInputFieldPropsType>

export const PhoneInputFormField = <TFieldValues extends FieldValues = FieldValues>(
  {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    ...props
  }: PhoneInputFormFieldPropsType<TFieldValues>
): ReactElement<PhoneInputFormFieldPropsType<TFieldValues>> => {
  const {
    field,
    error,
  } = useReactHookFormFieldController<TFieldValues>({
    name,
    rules,
    shouldUnregister,
    defaultValue,
  })

  return (
    <PhoneInputField
      {...field}
      error={error}
      {...props}
    />
  )
}
